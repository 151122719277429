import React, {useState} from 'react';
import {BsCaretRightFill} from "react-icons/bs";
import {buttonDangerStyle} from "../../../styles/forms";

const Dialog = ({
                  buttonType = 'icon',
                  icon = <BsCaretRightFill/>,
                  title = "Dialog Title",
                  text = "Dialog Text",
                  buttonText = "Open",
                  onProceed = () => console.log('Proceed...')
                }) => {
  const [showModal, setShowModal] = useState(false)

  const onConfirm = () => {
    onProceed()
    setShowModal(false)
  }

  let button;
  switch (buttonType) {
    case 'icon':
      button =
        <div onClick={() => setShowModal(true)} className="cursor-pointer flex flex-col justify-center">{icon}</div>
      break;
    case 'danger':
      button =
        <button
          className={buttonDangerStyle}
          type="button"
          onClick={() => setShowModal(true)}
        >
          {buttonText}
        </button>
      break;
    default:
      button = <button
        className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Open regular modal
      </button>
  }

  return (
    <>
      {button}
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-customDarkButtonBg outline-none focus:outline-none">
                {/*header*/}
                <div
                  className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold text-black dark:text-customGray-lightest">
                    {title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span
                      className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-black dark:text-customGray-lightest text-lg leading-relaxed">
                    {text}
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-black dark:text-customGray-lightest background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-customGreen text-white dark:bg-customDarkGreenButtonBg dark:text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => onConfirm()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Dialog