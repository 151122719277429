import React from 'react';
import {useOutletContext} from "react-router-dom";
import ConfigureHealthEndpoint from "../../../../components/Projects/ConfigureHealthEndpoint";

const Health = () => {
  const [project] = useOutletContext()

  return (
    <ConfigureHealthEndpoint project={project} />
  )
}

export default Health