import React, {useState} from 'react';
import {cancelInvitation, getAccount, removeUser} from "../../../../services/APIService/Accounts";
import {buttonStyleSmall} from "../../../../styles/forms";
import Card from "../../../../components/Shared/Card";
import {capitalize} from "../../../../services/String";
import {Link, useLoaderData} from "react-router-dom";
import {BsFillTrashFill} from "react-icons/bs";
import Dialog from "../../../../components/Shared/Dialog";

const Users = () => {
  const {data} = useLoaderData()

  const [users, setUsers] = useState(data.data.users)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [invitations, setInvitations] = useState(data.data.invitations)

  const submitCancelInvitation = (invitationId) => {
    cancelInvitation(invitationId)
      .then(res => {
        fetchAccount()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const submitRemoveUser = (userId) => {
    removeUser(userId)
      .then(res => {
        fetchAccount()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const fetchAccount = () => {
    getAccount()
      .then(res => {
        setUsers(res.data.data.users)
        setInvitations(res.data.data.invitations)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className="w-full mx-3">
      <Card title="Users">
        <table className="w-full p-2 rounded-md text-sm">
          <thead>
          <tr className="[&>td]:py-1 text-customGray-dark dark:text-customGray-light">
            <td>
              Name
            </td>
            <td className="hidden md:table-cell">
              Email
            </td>
            <td className="hidden md:table-cell">
              Role
            </td>
            <td className="flex justify-end">
              <Link to={`/app/settings/invite`} className={buttonStyleSmall}>
                Invite
              </Link>
            </td>
          </tr>
          </thead>
          <tbody>
          {
            users.map(user => <tr key={user.id}
                                  className="border-t border-green-green [&>td]:py-3 text-customGray-darkest dark:text-customGray-lightest">
              <td>
                {user.name}
              </td>
              <td className="hidden md:table-cell">
                {user.email}
              </td>
              <td className="hidden md:table-cell">
                {capitalize(user.pivot.role)}
              </td>
              <td className="flex justify-end [&>button]:mx-3 text-red-500">
                {
                  currentUser.id !== user.id ? <Dialog
                    title="Confirm Remove User"
                    text="Are you sure you want to remove this user from the account?"
                    icon={<BsFillTrashFill/>}
                    onProceed={() => submitRemoveUser(user.id)}
                  /> : null
                }
              </td>
            </tr>)
          }
          </tbody>
        </table>
      </Card>
      {
        invitations.length ? <Card title="Invitations">
          <table className="w-full p-2 rounded-md text-sm">
            <thead>
            <tr className="[&>td]:py-1 text-gray-500">
              <td>
                Email
              </td>
              <td>
                Role
              </td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            {
              invitations.map(invitation => <tr key={invitation.id}
                                                className="border-t border-green-green [&>td]:py-3 text-customGray-darkest dark:text-customGray-lightest">
                <td>
                  {invitation.email}
                </td>
                <td>
                  {capitalize(invitation.role)}
                </td>
                <td className="flex justify-end [&>button]:mx-3 text-red-500">
                  <Dialog
                    title="Confirm Cancel Invitation"
                    text="Are you sure you want to cancel the invitation?"
                    icon={<BsFillTrashFill/>}
                    onProceed={() => submitCancelInvitation(invitation.id)}
                  />
                </td>
              </tr>)
            }
            </tbody>
          </table>
        </Card> : null
      }
    </div>
  )
}

export default Users