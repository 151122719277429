import React, {useEffect, useState} from 'react';
import AuthLayout from "../../../layouts/Auth";
import FormContainer from "../FormContainer";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {formBlock, inputErrorStyle, inputErrorStyleCenter, inputStyle, labelStyle} from "../../../styles/forms";
import {useForm} from "react-hook-form";
import APIService from "../../../services/APIService";
import {saveLoginResponse} from "../../../services/Auth";

const Signup = () => {
  const { register, setValue, watch, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState("")
  const [searchParams] = useSearchParams()
  const [disableEmail, setDisableEmail] = useState(false)
  const navigate = useNavigate()

  const onSubmit = (data) => {
    APIService.register(data)
      .then(res => {
        saveLoginResponse(res.data)
        navigate('/app')
      })
      .catch(err => {
        if(err.response && err.response.data && err.response.data.message){
          setMessage(err.response.data.message)
        }
      })
  };

  useEffect(() => {
    if(searchParams.get('email')){
      setDisableEmail(true)
      setValue('email', searchParams.get('email'))
    }
  }, [searchParams]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthLayout>
      <div>
        <FormContainer>
          <p className="text-center text-2xl font-bold mb-10 dark:text-customGray-lightest">
            Create your free account
          </p>
          {
            !disableEmail ? <p className="text-center text-sm mb-10 dark:text-customGray-lightest">
              Once you create your account, you can configure your projects and select the right plan for you.
            </p> : null
          }
          <form className="w-[400px]" onSubmit={handleSubmit(onSubmit)}>
            <div className={formBlock}>
              <label className={labelStyle}>Email</label>
              <input disabled={disableEmail} className={inputStyle} type="email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })}/>
              {errors.email && <p className={inputErrorStyle}>Email is required and must be valid</p>}
            </div>
            <div className={formBlock}>
              <label className={labelStyle}>Name</label>
              <input className={inputStyle} type="text" {...register("name", { required: true })} />
              {errors.name && <p className={inputErrorStyle}>Name is required</p>}
            </div>
            <div className={formBlock}>
              <label className={labelStyle + ' flex justify-between'}>
                <div>
                  Password
                </div>
              </label>
              <input className={inputStyle} type="password" {...register("password", { required: true })}/>
              {errors.password && <p className={inputErrorStyle}>Password is required</p>}
            </div>
            <div className={formBlock}>
              <label className={labelStyle + ' flex justify-between'}>
                <div>
                  Confirm Password
                </div>
              </label>
              <input className={inputStyle} type="password" {...register("c_password", { required: true, validate: (val) => {
                  if (watch('password') !== val) {
                    return "Your passwords do not match";
                  }
                  if(val === ""){
                    return "Password confirmation required";
                  }
                } })}/>
              {errors.c_password && <p className={inputErrorStyle}>{errors.c_password.message === '' ? 'Password confirmation is required' : errors.c_password.message}</p>}
            </div>
            <div className={formBlock}>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" className="sr-only peer" {...register("agree", { required: true })} />
                <div className="w-11 h-6 bg-gray-200 dark:bg-customGray-dark peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-customGreen"></div>
                <span className="ml-3 text-sm text-customGray-dark dark:text-customGray-lightest">I agree to the <Link to={`${process.env.REACT_APP_WEBSITE_URL}terms-and-conditions`} className="text-customGreen">Terms and Conditions</Link> and <Link to={`${process.env.REACT_APP_WEBSITE_URL}privacy-policy`} className="text-customGreen">Privacy Policy</Link></span>
              </label>
              {errors.agree && <p className={inputErrorStyleCenter}>Please agree to the terms and conditions</p>}
            </div>
            <div>
              <button className="w-full bg-customGreen p-2 rounded text-white text-sm" type="submit">
                Submit
              </button>
              {message && <p className={inputErrorStyleCenter}>{message}</p>}
            </div>
          </form>
        </FormContainer>
        {
          !disableEmail ? <div className="m-5 text-sm">
            <p className="text-center dark:text-customGray-lightest">
              Already have an account?
            </p>
            <Link to="/auth/login">
              <p className="text-center text-customGreen cursor-pointer">
                Sign In
              </p>
            </Link>
          </div> : null
        }
      </div>
    </AuthLayout>
  )
}

export default Signup