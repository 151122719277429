import React, {useEffect, useState} from 'react';
import {buttonStyle} from "../../../styles/forms";
import Card from "../../Shared/Card";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {updateProject} from "../../../services/APIService/Projects";

const ConfigureRecipients = ({account, currentRecipients}) => {
  const {register, setValue, handleSubmit} = useForm({});
  const [loading, setLoading] = useState(false)
  const [recipientIds, setRecipientIds] = useState([])
  const navigate = useNavigate()
  const {project_id} = useParams()


  const getRecipientIds = () => {
    for (const recipient of currentRecipients) {
      setRecipientIds([...recipientIds, recipient.id])
      setValue('user-' + recipient.id, 'on')
    }
  }

  const toggleUserNotification = (e) => {
    const array = JSON.parse(e.target.value)
    if (recipientIds.includes(array.userId)) {
      setRecipientIds(recipientIds.filter(recipient => recipient !== array.userId))
    } else {
      setRecipientIds([...recipientIds, array.userId])
    }
  }

  useEffect(() => {
    getRecipientIds()
  }, []);//eslint-disable-line

  const saveRecipients = () => {
    setLoading(true)
    let recipientString = recipientIds.join(',')
    if (recipientString === '') {
      recipientString = 'null';
    }
    updateProject(project_id, {
      recipients: recipientString
    })
      .then(res => {
        setLoading(false)
        navigate('.', { replace: true })
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <Card title="Notification Recipients">
      <form onSubmit={handleSubmit(saveRecipients)}>
        {account ? account.users.map(user => {
          return <div key={user.id}>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" {...register(`user-${user.id}`)}
                     value={JSON.stringify({userId: user.id, value: recipientIds.includes(user.id)})}
                     onChange={toggleUserNotification}/>
              <div
                className="w-11 h-6 bg-gray-200 dark:bg-customGray-dark peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-customGreen"></div>
              <span className="ml-3 text-sm text-customGray-dark dark:text-customGray-lightest">{user.name}</span>
            </label>
          </div>
        }) : null}
        <div className="flex justify-end">
          <div className="text-right">
            <button className={buttonStyle} type="submit" disabled={loading}>
              Update Recipients
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default ConfigureRecipients