import React from 'react';
import {capitalize} from "../../../services/String";

const IssueStatusButton = ({status}) => {
  let errorColorClass
  switch (status) {
    case 'Canceled':
    case 'Closed':
    case 'Resolved':
      errorColorClass = 'bg-[#1C3329] border border-[#183D30]'
      break;
    default:
      errorColorClass = 'bg-[#1C2B41] border border-[#0A326B]'
  }

  return (
    <div
      className={`flex flex-col justify-center ${errorColorClass} px-3 rounded-md max-h-[28px] w-fit text-sm text-white`}>
      {capitalize(status)}
    </div>
  )
}

export default IssueStatusButton