import React from 'react';
import ConfigureRepicients from "../../../../components/Projects/ConfigureRepicients";
import {useOutletContext} from "react-router-dom";

const ProjectNotifications = () => {
  const [project, account] = useOutletContext();

  return (
    <div>
      <ConfigureRepicients account={account} currentRecipients={project.recipients} />
    </div>
  )
}

export default ProjectNotifications