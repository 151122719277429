import React from 'react';
import Warning from "../../Shared/Warning";
import {Link} from "react-router-dom";

const UpgradeApiWarning = ({projectId}) => {
  return (
    <div className="my-3">
      <Warning>
        Project is free. In order to publish your status page or test your api periodically, <Link to={`/app/projects/${projectId}/upgrade`}
                                                                       className="text-customGreen underline">upgrade</Link> the
        project to paid.
      </Warning>
    </div>
  )
}

export default UpgradeApiWarning