import React, {useEffect, useState} from 'react';
import Dropdown from "../Shared/Dropdown";
import {Link, NavLink, useLocation} from "react-router-dom";
import {BsBuildings, BsGlobe2, BsMoonFill, BsSun} from "react-icons/bs";
import {FaBars, FaTimes} from "react-icons/fa";
import {updateCurrentUser} from "../../services/APIService/Auth";

const Nav = () => {
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState({id: 0})
  const [showMobileNav, setShowMobileNav] = useState(false)
  const location = useLocation()

  const fetchUserAndAccountData = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      setAccounts(user.accounts)

      const selectedAccountStorage = localStorage.getItem('selected_account')
      if (selectedAccountStorage) {
        setSelectedAccount(JSON.parse(selectedAccountStorage))
      }
    }
  }

  useEffect(() => {
    fetchUserAndAccountData()
    window.addEventListener("storage", () => fetchUserAndAccountData())

    return () => {
      window.removeEventListener("storage", () => fetchUserAndAccountData())
    }
  }, []);

  useEffect(() => {
    setShowMobileNav(false)
  }, [location]);

  const toggleMode = () => {
    if(loading){
      return;
    }
    setLoading(true)
    const switchingToMode = localStorage.getItem('mode') === 'dark' ? 'light' : 'dark'
    localStorage.setItem("mode", switchingToMode)
    window.dispatchEvent(new Event("storage"));
    //update profile
    updateCurrentUser({
      dark_mode: (switchingToMode === 'dark')
    }).then(res => {
      setLoading(false)
    })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className="flex justify-between">
        {
          showMobileNav ? <div
            className="absolute m-0 bg-white dark:bg-customDarkBg dark:text-customGray-lightest z-10 h-full w-full p-3">
            {
              (accounts && accounts.length > 1) ? <>
                {
                  selectedAccount ? <div>
                    <NavLink to="/app/accounts" className="align-sub leading-[50px] mx-3 text-lg font-bold">
                      {selectedAccount.name}
                    </NavLink>
                  </div> : null
                }
                <NavLink to="/app/accounts"
                         className={({isActive}) => `align-sub leading-[50px] mx-3 flex flex-col justify-center ${isActive ? "[&>div]:bg-customLightGreen [&>div]:dark:bg-customDarkButtonBg [&>div]:dark:text-customGreen" : ""}`}>
                  <div className="p-5 flex gap-2 rounded">
                    <div className="flex flex-col justify-center">
                      <BsBuildings className="h-5 w-5"/>
                    </div>
                    <div className="text-lg">
                      Accounts
                    </div>
                  </div>
                </NavLink>
              </> : null
            }
            {
              localStorage.getItem('selected_account') ? <NavLink to={`/app/projects`}
                                                                  className={({isActive}) => `align-sub leading-[50px] mx-3 flex flex-col justify-center ${isActive ? "[&>div]:bg-customLightGreen [&>div]:dark:bg-customDarkButtonBg [&>div]:dark:text-customGreen" : ""}`}>
                <div className="flex gap-2 p-5 rounded">
                  <div className="flex flex-col justify-center">
                    <BsGlobe2 className="h-5 w-5"/>
                  </div>
                  <div className="text-lg">
                    Projects
                  </div>
                </div>
              </NavLink> : null
            }
            <div className="absolute right-5 top-5 cursor-pointer" onClick={() => setShowMobileNav(false)}>
              <FaTimes className="w-7 h-7"/>
            </div>
          </div> : null
        }
        <div className="block md:hidden flex p-4">
          <div className="flex flex-col justify-center cursor-pointer dark:text-customGray-lightest"
               onClick={() => setShowMobileNav(!showMobileNav)}>
            <FaBars/>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <Link to="/app/projects/search">
            <img src="/assets/svgs/full_logo.svg" className="w-[125px] hidden md:block mx-3" alt="Logo"/>
            <img src="/assets/svgs/logo.svg" className="w-[20px] block md:hidden" alt="Logo"/>
          </Link>
        </div>
        <div className="hidden md:flex">
          {
            (accounts && accounts.length > 1) ? <>
              {
                selectedAccount ? <div className="hidden md:block">
                  <Link to="/app/accounts"
                        className="align-sub leading-[50px] mx-3 text-sm font-bold dark:text-customGray-lightest">
                    {selectedAccount.name}
                  </Link>
                </div> : null
              }
              <NavLink to="/app/accounts"
                       className={({isActive}) => `align-sub leading-[50px] mx-3 dark:text-customGray-lightest flex flex-col justify-center ${isActive ? "[&>div]:bg-customLightGreen [&>div]:dark:bg-customDarkButtonBg [&>div]:dark:text-customGreen" : ""}`}>
                <div className="flex gap-2 px-2 py-1 rounded">
                  <div className="flex flex-col justify-center">
                    <BsBuildings className="h-3 w-3"/>
                  </div>
                  <div className="text-sm">
                    Accounts
                  </div>
                </div>
              </NavLink>
            </> : null
          }
          {
            localStorage.getItem('selected_account') ? <NavLink to={`/app/projects`}
                                                                className={({isActive}) => `align-sub leading-[50px] mx-3 dark:text-customGray-lightest flex flex-col justify-center ${isActive ? "[&>div]:bg-customLightGreen [&>div]:dark:bg-customDarkButtonBg [&>div]:dark:text-customGreen" : ""}`}>
              <div className="flex gap-2 px-2 py-1 rounded">
                <div className="flex flex-col justify-center">
                  <BsGlobe2 className="h-3 w-3"/>
                </div>
                <div className="text-sm">
                  Projects
                </div>
              </div>
            </NavLink> : null
          }
        </div>
        <div className="flex">
          <div
            className="hidden md:flex justify-center flex-col cursor-pointer text-customGray-darkest dark:text-customGray-lightest"
            onClick={() => toggleMode()}>
            <BsMoonFill className="flex dark:hidden"/>
            <BsSun className="hidden dark:flex"/>
          </div>
          <Dropdown darkModeToggle={() => toggleMode()}/>
        </div>
      </div>
    </>
  )
}

export default Nav