import React from 'react';

const AuthLayout = ({children}) => {
  return (
    <div className="w-[100vw] h-[100vh] flex flex-col justify-center">
      <div className="flex justify-center">
        {children}
      </div>
    </div>
  )
}

export default AuthLayout