import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
import isToday from "dayjs/plugin/isToday"

dayjs.extend(relativeTime)
dayjs.extend(isToday)

export const toFriendlyDate = (date) => {
  const now = dayjs()
  const dateInQuestion = dayjs(date)

  if(now.diff(dateInQuestion, "minutes") < 60){
    return dayjs(date).fromNow()
  }else if(dateInQuestion.isToday()){
    return dayjs(date).format('h:mm A')
  }else{
    return dayjs(date).format('MMMM D, YYYY h:mm A')
  }
}