import React, {useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {buttonStyleSmall} from "../../../../styles/forms";
import {capitalize} from "../../../../services/String";
import CreateAccount from "./CreateAccount";
import {Link, useLoaderData} from "react-router-dom";
import {BsFillForwardFill} from "react-icons/bs";

const AccountsSettings = () => {
  const {data} = useLoaderData()
  const [showCreateAccount, setShowCreateAccount] = useState(false)

  return (
    <div className="w-full mx-3">
      <div className="transition-all ease-out duration-1000" style={{
        maxHeight: showCreateAccount ? 300 : 0,
        opacity: showCreateAccount ? 1 : 0,
        overflow: "hidden"
      }}>
        <Card title="Create Account">
          <CreateAccount/>
        </Card>
      </div>
      <div className="transition-all duration-300">
        <Card title="Accounts">
          <table className="w-full p-2 rounded-md text-sm">
            <thead>
            <tr className="[&>td]:py-1 text-customGray-dark dark:text-customGray-light">
              <td>
                ID
              </td>
              <td>
                Name
              </td>
              <td className="hidden md:table-cell">
                Role
              </td>
              <td className="flex justify-end">
                <button className={buttonStyleSmall} onClick={() => setShowCreateAccount(!showCreateAccount)}>
                  Create Account
                </button>
              </td>
            </tr>
            </thead>
            <tbody>
            {
              data.data.map(account => <tr key={account.id}
                                          className="border-t border-green-green [&>td]:py-3 text-customGray-darkest dark:text-customGray-lightest">
                <td>
                  {account.id}
                </td>
                <td>
                  {account.name}
                </td>
                <td className="hidden md:table-cell">
                  {capitalize(account.pivot.role)}
                </td>
                <td className="flex justify-end [&>button]:mx-3">
                  <Link to={`/app/settings/accounts/${account.id}`}>
                    <BsFillForwardFill className="cursor-pointer !text-white"/>
                  </Link>
                </td>
              </tr>)
            }
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  )
}

export default AccountsSettings