import React, {useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {buttonStyleSmall} from "../../../../styles/forms";
import {Link, useLoaderData} from "react-router-dom";
import {BsCupHot, BsFillForwardFill} from "react-icons/bs";
import CreateSupportTicket from "./CreateSupportTicket";
import IssueStatusButton from "../../../../components/Projects/IssueStatusButton";

const Support = () => {
  const {data} = useLoaderData()
  const [showCreateIssue, setShowCreateIssue] = useState(false)

  return (
    <div className="w-full mx-3">
      <div className="transition-all ease-out duration-1000" style={{
        maxHeight: showCreateIssue ? 400 : 0,
        opacity: showCreateIssue ? 1 : 0,
        overflow: "hidden"
      }}>
        <Card title="Create Support Ticket">
          <CreateSupportTicket />
        </Card>
      </div>
      <div className="transition-all duration-300">
        <Card title="Support Tickets">
          <table className="w-full p-2 rounded-md text-sm">
            <thead>
            <tr className="[&>td]:py-1 text-customGray-dark dark:text-customGray-light">
              <td>
                Issue ID
              </td>
              <td>
                Summary
              </td>
              <td className="hidden md:table-cell">
                Status
              </td>
              <td className="flex justify-end">
                <button className={buttonStyleSmall} onClick={() => setShowCreateIssue(!showCreateIssue)}>
                  Create<span className="hidden md:inline-block ml-1">Support Request</span>
                </button>
              </td>
            </tr>
            </thead>
            <tbody>
            {
              data.data.map(issue => <tr key={issue.id}
                                           className="border-t border-green-green [&>td]:py-3 text-customGray-darkest dark:text-customGray-lightest">
                <td className="min-w-[100px]">
                  {issue.issue_id}
                </td>
                <td className="truncate max-w-[200px]">
                  {issue.summary}
                </td>
                <td className="hidden md:table-cell">
                  <IssueStatusButton status={issue.status} />
                </td>
                <td className="flex justify-end [&>button]:mx-3">
                  <Link to={`/app/settings/support/${issue.issue_id}`}>
                    <BsFillForwardFill className="cursor-pointer !text-white"/>
                  </Link>
                </td>
              </tr>)
            }
            {
              data.data.length ? null : <tr>
                <td colSpan={4} className={`text-center dark:text-customGray-lightest`}>
                  <div className="flex justify-center">
                    <BsCupHot className="w-10 h-10 m-4"/>
                  </div>
                  <p className="text-lg">
                    No Support Requests
                  </p>
                </td>
              </tr>
            }
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  )
}

export default Support
