import React from 'react';
import {capitalize} from "../../../services/String";
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import {BsFillDashCircleFill} from "react-icons/bs";

const StatusButton = ({status}) => {
  let errorColorClass, icon, iconColorClass
  switch (status) {
    case 'created':
    case 'in progress':
      errorColorClass = 'bg-customGray-lightest'
      icon = <BsFillDashCircleFill/>
      iconColorClass = 'text-customGray-light'
      break;
    case 'error':
      errorColorClass = 'bg-red-500 opacity-90'
      icon = <AiFillCloseCircle/>
      iconColorClass = 'text-red-800'
      break;
    default:
      errorColorClass = 'bg-customGreen'
      icon = <AiFillCheckCircle/>
      iconColorClass = 'text-white'
  }

  return (
    <div className={`flex ${errorColorClass} px-5 py-1 rounded-full gap-3 max-h-[35px] w-fit`}>
      <div className={`flex flex-col justify-center ${iconColorClass}`}>
        {React.cloneElement(icon)}
      </div>
      <div className={`text-customGray-darkest text-white leading-7`}>
        {capitalize(status)}
      </div>
    </div>
  )
}

export default StatusButton