import React from 'react';
import Card from "../../../../components/Shared/Card";
import UpdateProfile from "../../../../components/Auth/UpdateProfile";
import UpdatePassword from "../../../../components/Auth/UpdatePassword";
import UpdatePhone from "../../../../components/Auth/UpdatePhone";
import {useLoaderData} from "react-router-dom";

const Authentication = () => {
  const {data} = useLoaderData()

  return (
    <div className="w-full mx-3">
      <Card title="Update Profile">
        <UpdateProfile user={data.data} />
      </Card>
      <Card title="Update Phone">
        <UpdatePhone user={data.data} />
      </Card>
      <Card title="Update Password">
        <UpdatePassword />
      </Card>
    </div>
  )
}

export default Authentication