import fetch from '../../auth/FetchInterceptor'

export const login = (data) => {
  return fetch({
    url: `/auth/login`,
    method: 'POST',
    data
  })
}

export const register = (data) => {
  return fetch({
    url: `/auth/register`,
    method: 'POST',
    data
  })
}

export const logout = () => {
  return fetch({
    url: `/auth/logout`,
    method: 'POST'
  })
}

export const getCurrentUser = () => {
  return fetch({
    url: `/auth/current`,
    method: 'GET'
  })
}

export const resendVerificationEmail = () => {
  return fetch({
    url: `/auth/emails/verify/send`,
    method: 'POST'
  })
}

export const updateCurrentUser = (data) => {
  return fetch({
    url: `/auth/current`,
    method: 'PUT',
    data
  })
}

export const sendResetPasswordEmail = (data) => {
  return fetch({
    url: `/auth/reset`,
    method: 'POST',
    data
  })
}

export const updatePasswordWithToken = (id, hash, data) => {
  return fetch({
    url: `/auth/update/${id}/${hash}`,
    method: 'POST',
    data
  })
}

export const verify = (id, hash) => {
  return fetch({
    url: `/auth/email/verify/${id}/${hash}`,
    method: 'POST'
  })
}

export const updatePassword = (data) => {
  return fetch({
    url: `/auth/password`,
    method: 'POST',
    data
  })
}

export const updateCurrentPhone = (data) => {
  return fetch({
    url: `/auth/current/phone`,
    method: 'POST',
    data
  })
}

export const updateCurrentUserSettings = (data) => {
  return fetch({
    url: `/auth/current/settings`,
    method: 'POST',
    data
  })
}