import React, {useEffect, useState} from 'react';

const Counter = () => {
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    counter < 60 && setTimeout(() => setCounter(counter + 1), 1000);
  }, [counter]);

  return (
    <>
      {counter < 60 ? <>{counter}s</> : <>>1m</>}
    </>
  )
}

export default Counter