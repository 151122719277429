import React, {useState} from 'react';
import Card from "../../Shared/Card";
import {
  buttonStyle,
  formBlock,
  inputErrorStyle,
  inputFileStyle,
  inputSelectStyle,
  inputStyle,
  labelStyle
} from "../../../styles/forms";
import {useForm} from "react-hook-form";
import {updateStatusPage} from "../../../services/APIService/Projects";
import {Link, useNavigate} from "react-router-dom";

const StatusPageSettings = ({statusPage, projectId}) => {
  let defaultValues = {
    name: statusPage?.name ?? '',
    title: statusPage?.title ?? '',
    description: statusPage?.description ?? 'Step into the hub of real-time insights and updates for our suite of applications. This is your go-to destination for both current and past data, offering a comprehensive view of system performance and key metrics. Should there be any disruptions in service, rest assured that timely notifications will be prominently displayed here for your convenience.',
    page_status: statusPage?.page_status ?? 'draft'
  }

  const {register, setValue, setError, handleSubmit, formState: {errors}} = useForm({
    defaultValues
  });
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const onSubmit = (data) => {
    if(data.logo.length){
      //there is a file, validate
      let errors = []
      if(data.logo[0].size > 500000){
        errors.push("Cannot exceed 500kb")
      }
      if(!['image/png', 'image/jpg', 'image/jpeg', 'image/svg', 'image/svg+xml'].includes(data.logo[0].type)){
        errors.push("Must be of image type: svg, jpg or png.")
      }
      if(errors.length){
        setError("logo", {type: 'custom', message: errors.join(". ")})
        return;
      }
      data.logo = data.logo[0]
    }
    setLoading(true)
    updateStatusPage(projectId, data)
      .then(res => {
        if(res.status === 200){
          navigate(".", {replace: true})
        }else if(res.data?.errors?.name){
          if(res.data?.errors?.name){
            setError("name", {type: 'custom', message: res.data?.errors?.name[0]})
          }
          if(res.data?.errors?.title){
            setError("title", {type: 'custom', message: res.data?.errors?.title[0]})
          }
        }
        setLoading(false)
      })
  }

  const onChangeName = (event) => {
    setMessage("")
    setValue("name", event.target.value.toLowerCase().replace(/[\W_]+/g,"-"))
  }

  return (
    <Card title="Status Page" rightComponent={statusPage?.name && statusPage?.page_status === 'public' ? <Link className="dark:text-customGray-lightest cursor-pointer underline" target="_blank" to={process.env.REACT_APP_URL + 'status_pages/' + statusPage?.name}>Open Status Page</Link> : null}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={formBlock}>
          <label className={labelStyle}>Page Status</label>
          <select className={inputSelectStyle} {...register("page_status", {
            required: true,
            onChange: () => setMessage("")
          })}>
            <option value="public">Public</option>
            <option value="draft">Draft</option>
          </select>
          {errors.page_status && <p className={inputErrorStyle}>Page Status is required and must be valid</p>}
        </div>
        <div className={formBlock}>
          <label className={labelStyle}>Title</label>
          <input className={inputStyle} type="text" {...register("title", {
            required: true,
          })} />
          {errors.title && <p className={inputErrorStyle}>{errors.title?.message === '' ? 'Title is required and must be valid' : errors.title?.message}</p>}
        </div>
        <div className={formBlock}>
          <label className={labelStyle}>Logo</label>
          <input className={inputFileStyle} type="file" {...register("logo")} />
          {errors.logo && <p className={inputErrorStyle}>{errors.logo?.message === '' ? 'Logo must be valid' : errors.logo?.message}</p>}
        </div>
        <div className={formBlock}>
          <label className={labelStyle}>Unique Page Name</label>
          <div className="relative mb-4 flex flex-wrap items-stretch">
              <span
                className="flex text-gray-400 items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6]"
                id="basic-addon3">{process.env.REACT_APP_URL}status_pages/</span>
              <input className={`relative shadow m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:text-customGray-lightest`} type="text" {...register("name", {
                required: true,
                onChange: (event) => onChangeName(event)
              })} />
          </div>
          {errors.name && <p className={inputErrorStyle}>{errors.name?.message === '' ? 'Name is required and must be valid' : errors.name?.message}</p>}
        </div>
        <div className={formBlock}>
          <label className={labelStyle}>Description</label>
          <textarea rows={4}
                    placeholder="Page description will how up on your status page right below the status banner."
                    className={inputStyle} type="text" {...register("description", {
            required: true,
            onChange: () => setMessage("")
          })} />
          {errors.description && <p className={inputErrorStyle}>Description is required and must be valid</p>}
        </div>
        <div className="flex justify-end">
          <div className="text-right">
            {message && <p className={inputErrorStyle}>{message}</p>}
            <button className={buttonStyle} type="submit" disabled={loading}>
              Update Status Page
            </button>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default StatusPageSettings