import {
  createBrowserRouter,
  createRoutesFromElements,
  json,
  Navigate,
  Outlet,
  Route,
  RouterProvider
} from "react-router-dom";
import Auth from "./views/Auth";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import React, {useEffect, useState} from "react";
import AppLayout from "./layouts/App";
import Settings from "./views/App/Settings";
import Authentication from "./views/App/Settings/Authentication";
import Billing from "./views/App/Settings/Billing";
import Projects from "./views/App/Projects";
import Verify from "./components/Auth/Verify";
import VerifyEmail from "./views/App/Settings/Verify";
import Users from "./views/App/Settings/Users";
import Accounts from "./views/App/Accounts";
import AccountsSettings from "./views/App/Settings/AccountsSettings";
import Search from "./views/App/Projects/Search";
import Screenshots from "./views/App/Projects/Screenshots";
import PaymentMethods from "./views/App/Settings/PaymentMethods";
import Email from "./components/Auth/Email";
import Reset from "./components/Auth/Reset";
import Invite from "./views/App/Settings/Invite";
import {Toaster} from "react-hot-toast";
import EditProject from "./views/App/Projects/Edit";
import Account from "./views/App/Settings/Account";
import {getCurrentSelectedAccountId, getIfEmailIsVerified} from "./services/Auth";
import {estimateProject, getProject, getProjectForStatusPage, getProjects} from "./services/APIService/Projects";
import {getAccount, getAccounts, getSpecificAccount} from "./services/APIService/Accounts";
import CreateProjectForm from "./components/Projects/CreateProjectForm";
import ErrorPage from "./views/ErrorPage";
import UpgradeProject from "./views/App/Projects/Upgrade";
import {getCurrentUser} from "./services/APIService/Auth";
import Notifications from "./views/App/Settings/Notifications";
import Project from "./views/App/Projects/Project";
import ViewProject from "./views/App/Projects/ViewProject";
import ProjectNotifications from "./views/App/Projects/Notifications";
import {estimateNextInvoice, getInvoices} from "./services/APIService/Billing";
import Support from "./views/App/Settings/Support";
import {getIssue, getIssues} from "./services/APIService/Support";
import Issue from "./views/App/Settings/Support/Issue";
import Health from "./views/App/Projects/Health";
import StatusPages from "./views/StatusPages";

function App() {
  const [selectedAccountId, setSelectedAccountId] = useState(getCurrentSelectedAccountId())
  const [verified, setVerified] = useState(getIfEmailIsVerified())
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token') !== null)
  const [darkMode, setDarkMode] = useState(localStorage.getItem('mode') ?? 'dark')

  const setAccountAndLoggedIn = () => {
    setSelectedAccountId(getCurrentSelectedAccountId())
    setLoggedIn(localStorage.getItem('token') !== null)
    setDarkMode(localStorage.getItem('mode') ?? 'dark')
    setVerified(getIfEmailIsVerified())
  }

  useEffect(() => {
    window.addEventListener("storage", () => setAccountAndLoggedIn())

    return () => {
      window.removeEventListener("storage", () => setAccountAndLoggedIn())
    }
  }, []);

  const estimateAndProjectLoader = async () => {
    const [estimate] = await Promise.all([
      estimateProject(),
    ]);
    return json({estimate: estimate.data});
  }

  const getProjectLoader = async ({params}) => {
    const [project, account] = await Promise.all([
      getProject(params.project_id),
      getAccount()
    ]);
    return json({project: project.data.data, account: account.data.data});
  }

  const invoicesPageLoader = async () => {
    const [invoices, estimate] = await Promise.all([
      getInvoices(),
      estimateNextInvoice()
    ]);
    return json({initialInvoices: invoices.data.data, estimate: estimate.data});
  }

  const getAccountLoader = async ({params}) => {
    const [account] = await Promise.all([
      getSpecificAccount(params.account_id)
    ]);
    return json({account: account.data.data});
  }

  const getStatusPageLoader = async ({params}) => {
    const [project] = await Promise.all([
      getProjectForStatusPage(params.status_page_id)
    ]);
    return json({project: project.data});
  }

  const getIssueLoader = async ({params}) => {
    const [issue] = await Promise.all([
      getIssue(params.issue_id)
    ]);
    return json({issue: issue.data.data});
  }

  const browserRoutes = createBrowserRouter(createRoutesFromElements(
    <Route path='/' element={<Outlet/>} errorElement={<ErrorPage/>}>
      <Route path="/status_pages/:status_page_id" element={<StatusPages/>} loader={getStatusPageLoader} />
      <Route path="/verify/:id/:token" element={<Verify/>}/>
      <Route path="/auth" element={<Auth/>}>
        <Route path="reset/:user_id/:token" element={<Reset/>}/>
        <Route path="email" element={<Email/>}/>
        <Route path="login" element={<Login/>}/>
        <Route path="signup" element={<Signup/>}/>
        <Route index element={<Navigate to="login"/>}/>
      </Route>
      {
        loggedIn ? <Route path="/app" element={<AppLayout/>}>
          <Route path="accounts" element={<Accounts/>} loader={getAccounts}/>
          {
            selectedAccountId ? <>
              <Route path="settings" element={<Settings/>}>
                <Route path="authentication" element={<Authentication/>} loader={getCurrentUser}/>
                <Route path="notifications" element={<Notifications/>} loader={getCurrentUser}/>
                <Route path="account/:account_id" element={<Account/>} loader={getAccountLoader}/>
                <Route path="accounts/:account_id" element={<Account/>} loader={getAccountLoader}/>
                <Route path="accounts" element={<AccountsSettings/>} loader={getAccounts}/>
                <Route path="support" element={<Support/>} loader={getIssues}/>
                <Route path="support/:issue_id" element={<Issue/>} loader={getIssueLoader}/>
                <Route path="users" element={<Users/>} loader={getAccount}/>
                <Route path="invite" element={<Invite/>}/>
                <Route path="invoices" element={<Billing/>} loader={invoicesPageLoader}/>
                <Route path="payment_methods" element={<PaymentMethods/>}/>
                <Route path="verify_email" element={<VerifyEmail/>}/>
                <Route index path={"*"} element={<Navigate to="authentication"/>}/>
              </Route>
              <Route path="projects" element={verified ? <Projects/> : <Navigate to={"/app/settings/verify_email"}/>}>
                <Route path="search" element={<Search/>} loader={getProjects}/>
                <Route path="create" element={<CreateProjectForm/>} loader={estimateProject}/>
                <Route path=":project_id" element={<Project/>} loader={getProjectLoader}>
                  <Route path="health" element={<Health/>}/>
                  <Route path="screenshots" element={<Screenshots/>}/>
                  <Route path="notifications" element={<ProjectNotifications/>}/>
                  <Route path="settings" element={<EditProject/>}/>
                  <Route path="upgrade" element={<UpgradeProject/>} loader={estimateAndProjectLoader}/>
                  <Route path="view" element={<ViewProject/>}/>
                  <Route index path={"*"} element={<Navigate to="view"/>}/>
                </Route>
                <Route index path={"*"} element={<Navigate to="search"/>}/>
              </Route>
            </> : null
          }
          <Route index path={"*"} element={<Navigate to="accounts"/>}/>
        </Route> : null
      }
      <Route path={""} element={<Navigate to="auth"/>}/>
      <Route index path={"*"} element={<Navigate to="auth"/>}/>
    </Route>
  ))

  const darkStyle = {
    style: {
      background: '#1F2937',
      color: '#fff'
    }
  }

  return (
    <div className={`${darkMode}`}>
      <div className={`min-h-[100vh] bg-customLightBg dark:bg-customDarkBg`}>
        <Toaster toastOptions={darkMode === 'dark' ? darkStyle : {}}/>
        <div>
          <RouterProvider router={browserRoutes}/>
        </div>
      </div>
    </div>
  );
}

export default App;
