import React from 'react';
import {useOutletContext} from "react-router-dom";
import Stats from "../Stats";
import Status from "../Status";

const ViewProject = () => {
  const [project] = useOutletContext();

  return project.project_type === 'Website' ? <Stats project={project}/> : <Status project={project}/>
}

export default ViewProject