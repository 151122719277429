import React from 'react';
import {useLoaderData} from "react-router-dom";
import {parseIntoDisplayStats} from "../../services/StatusPage";
import StatusBar from "../../components/Projects/StatusBar";
import GraphBar from "../../components/Projects/GraphBar";
import NotificationBar from "../../components/Shared/Notification";

const StatusPages = () => {
  const {project} = useLoaderData()
  const {displayStats, stats, overall} = parseIntoDisplayStats(project)

  return (
    <div className="bg-white min-h-[100vh] flex justify-center px-3">
      <div className="w-[100%] max-w-3xl">
        <div className="flex justify-center my-5">
          {
            project?.status_page?.logo_s3_url ? <img alt={project?.status_page?.title ?? 'Logo'} className="w-[75%] p-5" src={project.status_page.logo_s3_url} /> : <h1 className="text-3xl p-5">
              {project?.status_page?.title} Status Page
            </h1>
          }
        </div>
        <NotificationBar darkModeEnabled={false}>
          All systems operational!
        </NotificationBar>
        {
          project?.status_page?.description ? <p className="text-sm text-customGray-dark my-5">
            {project.status_page.description}
          </p> : null
        }
        <StatusBar stats={stats} overall={overall} status={'Active'} darkModeEnabled={false} />
        {
          displayStats.map(serviceStat => {
            if(serviceStat.type === 'boolean'){
              return <>
                <h1 className="font-bold mt-5 mb-1 align-middle">
                  {serviceStat.name}
                </h1>
                <StatusBar stats={serviceStat.stats} overall={serviceStat.total_over_sum} status={'Active'} darkModeEnabled={false}/>
              </>
            } else {
              return <>
                <h1 className="font-bold my-5 align-middle">
                  {serviceStat.name}
                </h1>
                <GraphBar
                  stats={serviceStat.stats}
                  label={serviceStat.label}
                />
              </>
            }
          })
        }
      </div>
    </div>
  )
}

export default StatusPages