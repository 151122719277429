import React, {useState} from 'react';
import {Link, useNavigate, useOutletContext} from "react-router-dom";
import {simulateError, testProject} from "../../../../services/APIService/Projects";
import Card from "../../../../components/Shared/Card";
import dayjs from "dayjs";
import StatusButton from "../../../../components/Projects/StatusButton";
import ConfigureKeyScreenshot from "../../../../components/Projects/ConfigureKeyScreenshot";
import {BsBugFill, BsFillPlayFill, BsFillRecordBtnFill} from "react-icons/bs";
import ConfigButton from "../../../../components/Projects/ConfigButton";
import Warning from "../../../../components/Shared/Warning";
import Counter from "../../../../components/Projects/Counter";
import NotificationBar from "../../../../components/Shared/Notification";

const Screenshots = () => {
  const [project] = useOutletContext()
  const navigate = useNavigate()
  const [testButtonDisabled, setTestButtonDisabled] = useState(false)
  const [reconfigure, setReconfigure] = useState(false)

  const submitTestProject = () => {
    setTestButtonDisabled(true)
    testProject(project.id)
      .then(res => {
        setTestButtonDisabled(false)
        navigate('.', {replace: true})
      })
      .catch(err => {
        console.log(err)
        setTestButtonDisabled(false)
      })
  }

  const submitSimulateError = () => {
    setTestButtonDisabled(true)
    simulateError(project.id)
      .then(res => {
        setTestButtonDisabled(false)
      })
      .catch(err => {
        console.log(err)
        setTestButtonDisabled(false)
      })
  }

  return (
    <>
      <Card title={project.name}>
        <div className="flex flex-col md:flex-row gap-5">
          {(project.key_screenshot && !reconfigure) ? <>
            <div className="md:w-[400px] border">
              <a href={project.key_screenshot.s3_url} target="_blank" rel="noreferrer">
                <img src={project.key_screenshot.s3_url} alt={project.key_screenshot.url}/>
              </a>
            </div>
            <div className="flex flex-col justify-between md:w-[65%] dark:text-customGray-light">
              <div>
                <p className="font-bold dark:text-customGray-lightest">
                  Key Screenshot
                </p>
                {
                  (project.settings && project.settings.settings && project.settings.settings.url) ? <p>
                    {project.settings.settings.url}
                  </p> : null
                }
                <p>
                  {dayjs(project.key_screenshot.created_at).format('MMMM D, YYYY h:mm A')}
                </p>
                <p>
                  Set by {project.key_screenshot.created_by_user.name}
                </p>
                {
                  project.free ? <div className="my-3">
                    <Warning>
                      Project is free. To set up periodic monitor, <Link to={`/app/projects/${project.id}/upgrade`}
                                                                         className="text-customGreen underline">upgrade</Link> the
                      project to paid.
                    </Warning>
                  </div> : null
                }
                {
                  project.ssl_data ? <>
                    {
                      project.ssl_data?.error_code ? <Warning>
                        There is a problem with your TLS Cert: {project.ssl_data?.error_code}
                      </Warning> : <NotificationBar>
                        TLS Cert: {project.ssl_data?.issuer} {project.ssl_data?.subjectName}
                      </NotificationBar>
                    }
                  </> : null
                }
                {
                  project.project_status === 'active' ? <NotificationBar>
                    Website Looks normal!
                  </NotificationBar> : null
                }
              </div>
              <div className="flex justify-between w-full">
                <div>
                  <div className="flex gap-3">
                    <StatusButton status={project.project_status}/>
                    <ConfigButton icon={<BsFillPlayFill/>} bgColorClass="bg-white"
                                  iconColorClass="text-black" textColorClass="text-black" disabled={testButtonDisabled}
                                  onClickFunction={() => submitTestProject()}>
                      {testButtonDisabled ? <Counter/> : "Test"}
                    </ConfigButton>
                    <ConfigButton icon={<BsFillRecordBtnFill/>} bgColorClass="bg-white"
                                  iconColorClass="text-black" textColorClass="text-black" disabled={testButtonDisabled}
                                  onClickFunction={() => setReconfigure(true)}>
                      Retake
                    </ConfigButton>
                    <ConfigButton icon={<BsBugFill/>} bgColorClass="bg-white"
                                  iconColorClass="text-black" textColorClass="text-black" disabled={testButtonDisabled}
                                  onClickFunction={() => submitSimulateError()}>
                      Simulate Error
                    </ConfigButton>
                  </div>
                </div>
              </div>
            </div>
          </> : <>
            <ConfigureKeyScreenshot showCancel={(project.key_screenshot)} cancelFunction={() => setReconfigure(false)}
                                    projectId={project.id} fetchProject={() => navigate('.', {replace: true})}/>
          </>}
        </div>
      </Card>
      {(project.latest_screenshots && project.latest_screenshots.length) ? <Card title="Latest Screenshots">
        {project.latest_screenshots.map((screenshot, key) => <div key={screenshot.id}
                                                                  className="flex flex-col md:flex-row gap-5 mb-3">
          <div className="md:w-[400px] border">
            <a href={screenshot.s3_url} target="_blank" rel="noreferrer">
              <img src={screenshot.s3_url} alt={screenshot.url}/>
            </a>
          </div>
          <div className="flex flex-col text-sm justify-between dark:text-customGray-light">
            <div>
              <p>
                {screenshot.url}
              </p>
              <p>
                Last test run by {screenshot.created_by_user ? screenshot.created_by_user.name : 'System'}
              </p>
              {
                screenshot.similarity_score !== null ? <p>
                  Similarity Score: {screenshot.similarity_score * 100}%
                </p> : null
              }
              <p>
                From {dayjs(screenshot.from).format('MMMM D, YYYY h:mm A')}{screenshot.to ?
                <span> - to {dayjs(screenshot.to).format('MMMM D, YYYY h:mm A')}</span> : null}
              </p>
              <p>
                Last test {dayjs(screenshot.last_test).format('MMMM D, YYYY h:mm A')}
              </p>
              <p>
                Test run {screenshot.test_count} time{screenshot.test_count > 1 ? 's' : ''}
              </p>
            </div>
            {
              screenshot.project_error ? <div>
                <StatusButton status="error"/>
                <p className="text-red-500 text-sm">
                  {screenshot.project_error.description}
                </p>
              </div> : <StatusButton status={screenshot.completed ? "passed" : "in progress"}/>
            }
            <hr className="md:hidden my-3"/>
          </div>
        </div>)}
      </Card> : null}
    </>
  )
}

export default Screenshots