import React from 'react';
import {BsCaretLeftFill, BsCaretRightFill} from "react-icons/bs";

const Pagination = ({paginationData, fetchFunction}) => {
  const onFetch = (page) => {
    //check page is within limits
    if(page > 0 && page <= paginationData.totalPages){
      fetchFunction(page)
    }
  }

  return (
    <div className="flex justify-center gap-5 text-sm dark:text-customGray-lightest">
      <div className={`flex ${paginationData.currentPage > 1 ? "text-customGreen cursor-pointer" : "text-customGray-dark"}`} onClick={() => onFetch(paginationData.currentPage - 1)}>
        <div className="flex flex-col justify-center">
          <BsCaretLeftFill />
        </div>
        Previous
      </div>
      <div>
        {paginationData.currentPage} of {paginationData.totalPages}
      </div>
      <div className={`flex ${paginationData.currentPage < paginationData.totalPages ? "text-customGreen cursor-pointer" : "text-customGray-dark"}`} onClick={() => onFetch(paginationData.currentPage + 1)}>
        Next
        <div className="flex flex-col justify-center">
          <BsCaretRightFill />
        </div>
      </div>
    </div>
  )
}

export default Pagination