import React from 'react';

const Card = ({title, rightComponent, children}) => {
  return (
    <div className="bg-white dark:bg-customDarkButtonBg w-full p-5 rounded-lg mb-3">
      <div className="flex justify-between">
        <h1 className="font-bold mb-5 align-middle dark:text-customGray-lightest">
          {title}
        </h1>
        <div className="min-w-fit">
          {
            rightComponent ? React.cloneElement(rightComponent) : null
          }
        </div>
      </div>
      {children}
    </div>
  )
}

export default Card