import React from 'react';
import dayjs from "dayjs";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";
import {Line} from "react-chartjs-2";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime)

const Stats = ({project}) => {
  const stats = [
    {
      label: "Tests today",
      value: project.stats ? project.stats.stats.today.tests : 0,
    }, {
      label: "Last test",
      value: project.latest_tests.length ? dayjs(project.latest_tests[0].created_at).fromNow() : "none",
    }, {
      label: "Incidents today",
      value: project.stats ? project.stats.stats.today.incidents : 0,
    },
  ]

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    tension: .25,
    scales: {
      y: {
        title: {
          display: true,
          text: 'milliseconds'
        },
        min: 0
      },
      x: {
        grid: {
          display: false,
          border: false
        },
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = []
  const values = []
  for (let i=0; i<10; i++){
    if(project.latest_tests[i]){
      labels.push(dayjs(project.latest_tests[i].created_at).format('h:mm'))
      values.push(project.latest_tests[i].response_time)
    }else{
      labels.push('...')
      values.push(0)
    }
  }

  labels.reverse()

  const data = {
    labels,
    datasets: [
      {
        label: 'Response time',
        // data: [20, 30, 20, 30, 20, 30, 20, 30, 20, 30],
        data: values.reverse(),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };

  const emptyData = {
    availability: 1,
    downtime: 0,
    tests: 0,
    average_speed: 0,
    incidents: 0,
  }

  const getStatRow = (label, data) => {
    return <tr className="[&>td]:p-3 [&>td]:text-center">
      <td>
        {label}
      </td>
      <td>
        {data.availability * 100}%
      </td>
      <td>
        {data.downtime} m
      </td>
      <td>
        {data.tests}
      </td>
      <td>
        {Math.round(data.average_speed)} ms
      </td>
      <td>
        {data.incidents}
      </td>
    </tr>
  }

  return (
    <div className="text-customGray-lightest">
      <div className="flex gap-3 w-full justify-between my-3">
        {
          stats.map(stat => <div key={stat.label} className="bg-white dark:bg-customDarkButtonBg px-5 py-3 w-full rounded">
            <div className="text-customGray-light text-sm mb-1">
              {stat.label}
            </div>
            <div>
              {stat.value}
            </div>
          </div>)
        }
      </div>
      <div className="bg-white dark:bg-customDarkButtonBg px-5 py-3 w-full rounded my-3">
        <div className="text-sm dark:text-customGray-light mb-3">
          Last Ten Tests Response Times
        </div>
        <Line data={data} options={options}/>
      </div>
      <div className="bg-white dark:bg-customDarkButtonBg my-3 rounded overflow-hidden">
        <table className="w-full text-sm divide-y divide-gray-600">
          <thead className="bg-customDarkButtonBgLighter">
          <tr className="[&>td]:p-3 [&>td]:text-customGray-light [&>td]:text-center">
            <td>
              Period
            </td>
            <td>
              Availability
            </td>
            <td>
              Downtime
            </td>
            <td>
              Tests
            </td>
            <td>
              Average Speed
            </td>
            <td>
              Incidents
            </td>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-600">
          {getStatRow('Today', project.stats ? project.stats.stats.today : emptyData)}
          {getStatRow('Last 7 Days', project.stats ? project.stats.stats.last_7_days : emptyData)}
          {getStatRow('Last 30 Days', project.stats ? project.stats.stats.last_30_days : emptyData)}
          {getStatRow('Last 365 Days', project.stats ? project.stats.stats.last_365_days : emptyData)}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Stats