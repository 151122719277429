export const formBlock = 'mb-6'
export const labelStyle = 'block text-customGray-darkest dark:text-customGray-lightest text-xs mb-2'

export const inputStyle = 'shadow appearance-none border border-gray-lightest dark:border-customGray-light rounded w-full py-2 px-3 text-customGray-darkest leading-tight focus:outline-none focus:shadow-outline dark:bg-customDarkButtonBg dark:text-customGray-lightest'
export const inputFileStyle = 'shadow appearance-none border border-gray-lightest dark:border-customGray-light rounded w-full py-2 px-3 text-customGray-darkest leading-tight focus:outline-none focus:shadow-outline dark:bg-customDarkButtonBg dark:text-customGray-lightest'

export const inputSelectStyle = 'shadow text-sm bg-white dark:bg-customDarkButtonBg border border-gray-lightest dark:border-customGray-light rounded w-full py-2 px-2 text-customGray-darkest dark:text-customGray-lightest leading-tight focus:outline-none focus:shadow-outline'
export const inputErrorStyle = 'text-red-500 text-xs mt-1 text-right'
export const inputErrorStyleCenter = 'text-red-500 text-xs mt-1 text-center'

export const buttonStyle = 'bg-customGreen dark:bg-customDarkGreenButtonBg py-2 px-8 rounded text-white text-sm disabled:opacity-40'

export const buttonDangerStyle = 'bg-red-500 py-2 px-8 rounded text-white text-sm disabled:opacity-40'

export const disabledButtonStyle = 'bg-gray-300 dark:text-customGray-dark dark:bg-customGray-darkest py-2 px-8 rounded text-white text-sm cursor-default'
export const buttonStyleSmall = 'bg-customGreen dark:bg-customDarkGreenButtonBg py-1 px-4 rounded text-white text-sm'

export const buttonStyleRight = 'bg-customGreen py-2 px-8 rounded text-white text-sm'

export const navButtonStyle = 'text-customGray-light text-sm'