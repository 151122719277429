import React, {useEffect, useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {capitalize, toCurrency} from "../../../../services/String";
import {
  getInvoices,
  getPaymentMethodsBillingSession,
  retryDueInvoices,
  streamInvoice
} from "../../../../services/APIService/Billing";
import dayjs from "dayjs";
import {BsFillForwardFill, BsReceipt} from "react-icons/bs";
import {Link, useLoaderData} from "react-router-dom";
import toast from "react-hot-toast";
import Pagination from "../../../../components/Shared/Pagination";
import Warning from "../../../../components/Shared/Warning";

const Billing = () => {
  const {initialInvoices, estimate} = useLoaderData()

  const [invoices, setInvoices] = useState(initialInvoices.invoices)
  const [dueInvoices, setDueInvoices] = useState(initialInvoices.invoices.filter(invoice => invoice.invoice_status === 'due'))
  const [paymentMethods, setPaymentMethods] = useState(initialInvoices.payment_methods)
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(initialInvoices.payment_methods.filter(method => method.default))
  const [billingSessionUrl, setBillingSessionUrl] = useState("#")
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({currentPage: initialInvoices.pagination.current_page, totalPages: initialInvoices.pagination.last_page})

  const retryInvoices = () => {
    if (!loading) {
      setLoading(true)
      toast.promise(retryDueInvoices(), {
        loading: 'Loading',
        success: 'Success!',
        error: 'Failed!',
      }).then(res => {
        fetchInvoices()
        setLoading(false)
      })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    }
  }

  const fetchInvoices = (page = 1) => {
    getInvoices(page)
      .then(res => {
        setInvoices(res.data.data.invoices)
        setPaymentMethods(res.data.data.payment_methods)
        setDefaultPaymentMethod(res.data.data.payment_methods.filter(method => method.default))
        setDueInvoices(res.data.data.invoices.filter(invoice => invoice.invoice_status === 'due'))
        setPagination({currentPage: res.data.data.pagination.current_page, totalPages: res.data.data.pagination.last_page})
        getUpdateBillingMethodSession()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getUpdateBillingMethodSession = () => {
    getPaymentMethodsBillingSession({return_url: '/app/settings/invoices'})
      .then(res => {
        setBillingSessionUrl(res.data.url)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getInvoiceTotal = (invoiceItems) => {
    let total = 0
    for (let i = 0; i < invoiceItems.length; i++) {
      total += invoiceItems[i].total
    }
    return total
  }

  const streamInvoicePdf = (invoiceId) => {
    streamInvoice(invoiceId)
      .then(res => {
        window.open(URL.createObjectURL(res.data));
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    const selected_account = JSON.parse(localStorage.getItem('selected_account'))

    selected_account.open_invoices = dueInvoices

    localStorage.setItem('selected_account', JSON.stringify(selected_account))

    window.dispatchEvent(new Event("storage"))
  }, [dueInvoices]);

  useEffect(() => {
    getUpdateBillingMethodSession()
  }, []);

  return (
    <div className="w-full mx-3">
      {
        dueInvoices.length ? <Card title="Invoices Due">
          <p className="text-sm dark:text-customGray-lightest">
            Your account has {dueInvoices.length} invoice{dueInvoices.length > 1 ? 's' : ''} past
            due. {paymentMethods.length === 0 ? <>
            Your account has no payment methods. Please <Link className="text-customGreen underline"
                                                              to={billingSessionUrl}>add one</Link>.
          </> : <>
            {
              defaultPaymentMethod.length === 1 ? <>
                <span className="underline text-customGreen cursor-pointer"
                      onClick={() => retryInvoices()}>Click Here</span> to retry your past due invoices with payment
                method ending in {defaultPaymentMethod[0].last4} or <Link className="text-customGreen underline"
                                                                          to={billingSessionUrl}>Click Here</Link> to
                update your payment method.
              </> : null
            }
          </>}
          </p>
        </Card> : null
      }
      <Card title="Invoices">
        {
          estimate.total > 0 ? <Warning>
            As of today, your next invoice is going to be ${estimate.total}, to be billed on the 1st of the month.
          </Warning> : null
        }
        <table className="w-full p-2 rounded-md text-sm">
          <thead>
          <tr className="[&>td]:py-1 text-customGray-dark dark:text-customGray-light">
            <td>
              ID
            </td>
            <td>
              Date
            </td>
            <td>
              Total
            </td>
            <td>
              Status
            </td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {
            invoices.length === 0 ? <tr>
              <td colSpan="4" className="text-center p-3 text-customGray-dark border-t border-green">
                <div className="flex justify-center">
                  <BsReceipt className="w-10 h-10 m-4"/>
                </div>
                <p className="text-lg">
                  No Invoices
                </p>
              </td>
            </tr> : null
          }
          {
            invoices.map(invoice => <tr key={invoice.id}
                                        className="border-t border-green-green [&>td]:py-3 text-customGray-darkest dark:text-customGray-lightest">
              <td>
                {invoice.id}
              </td>
              <td>
                {dayjs(invoice.created_at).format('MMM D, YYYY')}
              </td>
              <td>
                {toCurrency(getInvoiceTotal(invoice.invoice_items))}
              </td>
              <td>
                {capitalize(invoice.invoice_status)}
              </td>
              <td className="flex justify-end [&>button]:mx-3">
                <BsFillForwardFill className="cursor-pointer" onClick={() => streamInvoicePdf(invoice.id)}/>
              </td>
            </tr>)
          }
          </tbody>
        </table>
        {
          invoices.length > 0 ? <Pagination paginationData={pagination} fetchFunction={fetchInvoices} /> : null
        }
      </Card>
    </div>
  )
}

export default Billing