import React, {useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import {deleteAccount} from "../../../../services/APIService/Accounts";
import dayjs from "dayjs";
import Warning from "../../../../components/Shared/Warning";
import {buttonDangerStyle} from "../../../../styles/forms";
import EditAccount from "../AccountsSettings/EditAccount";
import {getCurrentUser} from "../../../../services/APIService/Auth";
import {updateUser} from "../../../../services/Auth";
import {capitalize} from "../../../../services/String";

const Account = () => {
  const {account_id} = useParams()
  const {account} = useLoaderData()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const currentUserIsAdmin = account.current_user_role === 'admin'

  const onDeleteAccount = () => {
    setLoading(true)
    deleteAccount(account_id)
      .then(res => {
        getCurrentUser()
          .then(res => {
            updateUser(res.data.data)
            localStorage.removeItem('selected_account')
            setLoading(false)
            navigate('/app')
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  return (
    <div className="w-full mx-3">
      <Card title="Account">
        <table className="w-full p-2 rounded-md text-sm dark:text-customGray-lightest">
          <tbody>
          <tr className="border-t border-green-green [&>td]:py-3">
            <td>
              Name
            </td>
            <td>
              {account.name}
            </td>
          </tr>
          <tr className="border-t border-green-green [&>td]:py-3">
            <td>
              Customer Since
            </td>
            <td>
              {dayjs(account.created_at).format('MMMM D, YYYY')}
            </td>
          </tr>
          <tr className="border-t border-green-green [&>td]:py-3">
            <td>
              Your Role
            </td>
            <td>
              {capitalize(account.current_user_role)}
            </td>
          </tr>
          <tr className="border-t border-green-green [&>td]:py-3">
            <td>
              # of Users
            </td>
            <td>
              {account.users.length}
            </td>
          </tr>
          <tr className="border-t border-green-green [&>td]:py-3">
            <td>
              # of Pending Invitations
            </td>
            <td>
              {account.invitations.length}
            </td>
          </tr>
          <tr className="border-t border-green-green [&>td]:py-3">
            <td>
              # Of Projects
            </td>
            <td>
              {account.projects.length}
            </td>
          </tr>
          </tbody>
        </table>
      </Card>
      {
        currentUserIsAdmin ? <>
          <Card title="Update Account">
            <EditAccount />
          </Card>
          <Card title="Danger Zone">
            <Warning>
              This will cancel your subscription and delete all of your account's data. Your data will not be recoverable.
            </Warning>
            <div className="mt-3 flex justify-end dark:text-customGray-lightest">
              {
                showDeleteAccount ? <div className="text-sm">
                    Are you sure? <span className="underline text-red-500 cursor-pointer mx-1" onClick={() => onDeleteAccount()}>Delete my Account</span>
                    <span className="underline cursor-pointer" onClick={() => setShowDeleteAccount(false)}>Cancel</span>
                  </div> :
                  <button disabled={loading} className={buttonDangerStyle} onClick={() => setShowDeleteAccount(true)}>
                    Delete Account
                  </button>
              }
            </div>
          </Card>
        </> : null
      }
    </div>
  )
}

export default Account