import React from 'react';

const Loading = () => {
  return (
    <div className={`dark:text-customGray-lightest w-[100%] flex justify-center`}>
      <div className={`mt-[15vh] flex flex-col justify-center`}>
        <div>
          <div className={`flex justify-center animate-pulse`}>
            <img src="/assets/svgs/logo.svg" className="w-[100px]" alt="Logo"/>
          </div>
          <div className={`mt-[5vh]`}>
            <TextLoadingReveal />
          </div>
        </div>
      </div>
    </div>
  )
}

export const TextLoadingReveal = () => {
  const text = "Loading...";

  return (
    <>
      <h1 className="text-2xl font-bold leading-6 text-customGray-dark dark:text-white">
        {text.match(/./gu).map((char, index) => {
          return (
            <span
              className="animate-text-reveal inline-block [animation-fill-mode:backwards]"
              key={`${char}-${index}`}
              style={{animationDelay: `${index * 0.1}s`}}
            >
        {char === " " ? "\u00A0" : char}
      </span>
          )
        })}
      </h1>
    </>
  );
};

export default Loading