import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {buttonStyle, formBlock, inputErrorStyle, inputStyle, labelStyle} from "../../../../../styles/forms";
import {createIssue} from "../../../../../services/APIService/Support";

const CreateSupportTicket = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({});
  const [message, setMessage] = useState("")
  const navigate = useNavigate()

  const onSubmit = (data) => {
    createIssue(data)
      .then((res) => {
        navigate(`/app/settings/support/${res.data.data.issue_id}`)
      })
      .catch(err => {
        setMessage(err.response.message)
      })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={formBlock}>
          <label className={labelStyle}>Summary</label>
          <input className={inputStyle} type="text" {...register("summary", { required: true, onChange: () => setMessage("") })} />
          {errors.summary && <p className={inputErrorStyle}>Summary is required and must be valid</p>}
        </div>
        <div className={formBlock}>
          <label className={labelStyle}>Description</label>
          <input className={inputStyle} type="text" {...register("description", { required: true, onChange: () => setMessage("") })} />
          {errors.description && <p className={inputErrorStyle}>Description is required and must be valid</p>}
        </div>
        <div className="flex justify-end">
          <button className={buttonStyle} type="submit">
            Create Support Ticket
          </button>
          {message && <p className={inputErrorStyle}>{message}</p>}
        </div>
      </form>
    </div>
  )
}

export default CreateSupportTicket