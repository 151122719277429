import React, {useEffect, useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {deleteProject, updateProject} from "../../../../services/APIService/Projects";
import {buttonStyle, formBlock, inputErrorStyle, inputStyle, labelStyle} from "../../../../styles/forms";
import Warning from "../../../../components/Shared/Warning";
import Dialog from "../../../../components/Shared/Dialog";
import StatusPageSettings from "../../../../components/Projects/StatusPageSettings";

const EditProject = () => {
  const [project] = useOutletContext()

  const {register, setValue, handleSubmit, formState: {errors}} = useForm({});
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {project_id} = useParams()

  const submitDeleteProject = () => {
    deleteProject(project.id)
      .then(res => {
        navigate('/app/projects')
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onSubmit = (data) => {
    setLoading(true)
    updateProject(project_id, data)
      .then(res => {
        navigate(`/app/projects/${res.data.data.id}/settings`)
        setLoading(false)
      })
      .catch(err => {
        setMessage(err.response.data.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    setValue('name', project.name)
  }, [project_id]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card title="Update Project">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={formBlock}>
            <label className={labelStyle}>Name</label>
            <input className={inputStyle} type="text" {...register("name", {
              required: true,
              onChange: () => setMessage("")
            })} />
            {errors.name && <p className={inputErrorStyle}>Name is required and must be valid</p>}
          </div>
          <div className="flex justify-end">
            <div className="text-right">
              {message && <p className={inputErrorStyle}>{message}</p>}
              <button className={buttonStyle} type="submit" disabled={loading}>
                Update Project
              </button>
            </div>
          </div>
        </form>
      </Card>
      {
        project.project_type === 'API' ? <StatusPageSettings statusPage={project.status_page} projectId={project.id} /> : null
      }
      <Card title="Danger Zone">
        <Warning>
          This will delete the project "{project.name}" and all of it's data. The data will not be recoverable.
        </Warning>
        <div className="flex justify-end">
          <Dialog
            buttonType="danger"
            title="Confirm Delete Project"
            text="Are you sure you want to delete this project?"
            buttonText="Delete Project"
            onProceed={() => submitDeleteProject()}
          />
        </div>
      </Card>
    </>
  )
}

export default EditProject