import React, {useEffect, useState} from 'react';
import {Link, Outlet, useNavigate, useNavigation} from "react-router-dom";
import Nav from "../../components/Nav";
import Warning from "../../components/Shared/Warning";
import Loading from "../../components/App";

const AppLayout = () => {
  const navigate = useNavigate()
  const {state} = useNavigation()
  const [hasPastDueInvoices, setHasPastDueInvoices] = useState(false)

  const getInvoicesPastDue = () => {
    const selected_account = JSON.parse(localStorage.getItem('selected_account'))

    if(selected_account){
      if(selected_account.open_invoices && selected_account.open_invoices.length > 0){
        setHasPastDueInvoices(true)
      }else{
        setHasPastDueInvoices(false)
      }
    }
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user.email_verified_at === null){
      navigate('/app/settings/verify_email')
    }

    getInvoicesPastDue()

    window.addEventListener("storage", () => getInvoicesPastDue())

    return () => {
      window.removeEventListener("storage", () => getInvoicesPastDue())
    }
  }, [navigate]);

  return (
    <>
      <div className="bg-white dark:bg-customDarkNavBg flex justify-center">
        <div className="max-w-7xl w-full">
          <Nav />
        </div>
      </div>
      {
        hasPastDueInvoices ? <div className="flex justify-center p-3">
          <div className="max-w-7xl w-full">
            <Warning>
              We couldn't process an invoices and your account is past due. Please <Link to={`/app/settings/invoices`} className="underline text-customGreen">update your payment method</Link> or <Link to={`/app/settings/invoices`} className="underline text-customGreen">retry the charge</Link> to avoid service interruption.
            </Warning>
          </div>
        </div> : null
      }
      <div className="flex justify-center">
        <div className="p-5 max-w-7xl w-full">
          {state === 'loading' ? <Loading /> : <Outlet />}
          {/*<Loading />*/}
        </div>
      </div>
    </>
  )
}

export default AppLayout