import React, {useEffect, useState} from 'react';
import {NavLink, Outlet, useLoaderData} from "react-router-dom";
import {Heading1Style} from "../../../../styles/headers";
import {BsBellFill, BsCheckSquareFill, BsFillCameraFill, BsGearFill, BsSpeedometer} from "react-icons/bs";
import {getProject} from "../../../../services/APIService/Projects";

const Project = () => {
  const {project, account} = useLoaderData()
  const [fetchedProject, setFetchedProject] = useState(project)

  const links = [
    {
      label: 'Stats',
      link: 'view',
      icon: <BsSpeedometer/>,
      project_types: [
        'Website',
        'API',
      ]
    }, {
      label: 'Screenshots',
      link: 'screenshots',
      icon: <BsFillCameraFill/>,
      project_types: [
        'Website',
      ]
    }, {
      label: 'Health',
      link: 'health',
      icon: <BsCheckSquareFill/>,
      project_types: [
        'API',
      ]
    }, {
      label: 'Notifications',
      link: 'notifications',
      icon: <BsBellFill/>,
      project_types: [
        'Website',
        'API',
      ]
    }, {
      label: 'Settings',
      link: 'settings',
      icon: <BsGearFill/>,
      project_types: [
        'Website',
        'API',
      ]
    }
  ]

  const refetchProject = () => {
    getProject(project.id)
      .then(res => {
        setFetchedProject(res.data.data)
      })
  }

  useEffect(() => {
    const intervalId = setInterval(refetchProject, 5 * 60 * 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, []);//eslint-disable-line

  return (
    <div>
      <h1 className={Heading1Style}>
        {fetchedProject.name}
      </h1>
      <div className="flex gap-5 my-3">
        {
          links.map(link => <React.Fragment key={link.label}>
            {
              link.project_types.includes(fetchedProject.project_type) ? <NavLink
                className={({isActive}) => `flex text-customGray-light text-sm gap-2 ${isActive ? 'text-customGreen' : ''}`}
                to={`/app/projects/${fetchedProject.id}/${link.link}`}>
                <div className="flex flex-col justify-center">
                  {link.icon}
                </div>
                <div>
                  {link.label}
                </div>
              </NavLink> : null
            }
          </React.Fragment>)
        }
      </div>
      <Outlet context={[fetchedProject, account]}/>
    </div>
  )
}

export default Project