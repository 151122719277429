import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {logout} from "../../../services/APIService/Auth";
import {getAcronym} from "../../../services/String";

const Dropdown = ({darkModeToggle}) => {
  const [show, setShow] = useState(false)
  const navigate = useNavigate()
  const [name, setName] = useState("")

  const onSignOut = () => {
    logout().then(res => {
      localStorage.clear()
      window.dispatchEvent(new Event("storage"));
      navigate('/auth/login')
    }).catch(err => {
      console.log(err)
      localStorage.clear()
      window.dispatchEvent(new Event("storage"));
      navigate('/auth/login')
    })
  }

  const setUserName = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user && user.name){
      setName(user.name)
    }else{
      setName("No Name")
    }
  }

  useEffect(() => {
    setUserName()
    window.addEventListener("storage", () => setUserName())

    return () => {
      window.removeEventListener("storage", () => setUserName())
    }
  }, []);

  return (
    <div className="relative inline-block text-left leading-[50px]">
      <div>
        <button onClick={() => setShow(!show)} type="button" className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white dark:bg-customDarkBg px-3 py-2 text-sm text-customGray-darkest dark:text-customGray-lightest hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
          <div className="bg-customGray-lightest dark:text-customGray-darkest p-1 mr-1 rounded-full w-[28px]">
            {getAcronym(name)}
          </div>
          <div className="hidden md:block leading-7">
            {name}
          </div>
          <div className="hidden md:block mt-1">
            <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
            </svg>
          </div>
        </button>
      </div>

      {
        show ? <div onClick={() => setShow(false)} className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-customDarkButtonBg dark:border dark:border-customGray-dark shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
          <div className="py-1" role="none">
            <Link to="/app/settings" className="text-customGray-darkest dark:text-customGray-lightest block px-4 py-2 text-sm dark:hover:bg-customDarkBg" role="menuitem" tabIndex="-1" id="menu-item-0">Settings</Link>
            <button onClick={() => {
              darkModeToggle()
            }} className="block md:hidden text-customGray-darkest dark:text-customGray-lightest block w-full px-4 py-2 text-left text-sm dark:hover:bg-customDarkBg" role="menuitem" tabIndex="-1" id="menu-item-3">Dark Mode</button>
            <button onClick={() => {
              onSignOut()
            }} className="text-customGray-darkest dark:text-customGray-lightest block w-full px-4 py-2 text-left text-sm dark:hover:bg-customDarkBg" role="menuitem" tabIndex="-1" id="menu-item-3">Sign out</button>
          </div>
        </div> : null
      }
    </div>

  )
}

export default Dropdown
