import React, {useState} from 'react';
import {buttonStyle, formBlock, inputErrorStyle, inputStyle, labelStyle} from "../../../styles/forms";
import {useForm} from "react-hook-form";
import {updateCurrentUser} from "../../../services/APIService/Auth";
import toast from "react-hot-toast";
import {updateUser} from "../../../services/Auth";
import {getDirtyValues} from "../../../services/Forms";

const UpdateProfile = ({user}) => {
  let defaultValues = {
    name: user.name,
    email: user.email
  }

  const {register, handleSubmit, formState: {errors}} = useForm({
    defaultValues
  });
  const [message, setMessage] = useState("")
  const [updateProfileButton, setUpdateProfileButton] = useState("Update Profile")

  const onSubmit = (data) => {
    const dirtyValues = getDirtyValues(data, defaultValues)
    if (Object.keys(dirtyValues).length) {
      updateCurrentUser(dirtyValues)
        .then(res => {
          setUpdateProfileButton('Profile Updated')
          updateUser(res.data.data)
        })
        .catch(err => {
          setMessage(err.response.data.message)
        })
    } else {
      toast.error('No changes in form')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={formBlock}>
        <label className={labelStyle}>Name</label>
        <input className={inputStyle} type="text" {...register("name", {
          required: true,
          onChange: () => setMessage("")
        })} />
        {errors.name && <p className={inputErrorStyle}>Name is required and must be valid</p>}
      </div>
      <div className={formBlock}>
        <label className={labelStyle}>Email</label>
        <input className={inputStyle} type="email" {...register("email", {
          required: true,
          pattern: /^\S+@\S+$/i,
          onChange: () => setMessage("")
        })} />
        {errors.email && <p className={inputErrorStyle}>Email is required and must be valid</p>}
      </div>
      <div className="flex justify-end">
        <button disabled={updateProfileButton !== "Update Profile"} className={buttonStyle} type="submit">
          {updateProfileButton}
        </button>
        {message && <p className={inputErrorStyle}>{message}</p>}
      </div>
    </form>
  )
}

export default UpdateProfile