import React, {useEffect, useState} from 'react';
import {GoBrowser, GoGear} from "react-icons/go";
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import StatusButton from "../../../../components/Projects/StatusButton";
import {TbWorldCheck} from "react-icons/tb";
import dayjs from "dayjs";
import {Heading1Style} from "../../../../styles/headers";
import {getProjects} from "../../../../services/APIService/Projects";
import {BsCodeSlash} from "react-icons/bs";

const Search = () => {
  const {data} = useLoaderData()
  const [results, setResults] = useState(data)
  const navigate = useNavigate()
  const [currentSearch, setCurrentSearch] = useState('')

  const changeSearch = (event) => {
    setCurrentSearch(event.target.value.toLowerCase())
    const searchResults = data.filter(project => project.name.toLowerCase().includes(event.target.value.toLowerCase()))
    setResults(searchResults)
  }

  const refetchData = () => {
    getProjects()
      .then(res => {
        const searchResults = res.data.filter(project => project.name.toLowerCase().includes(currentSearch))
        setResults(searchResults)
      })
  }

  useEffect(() => {
    if(localStorage.getItem('selected_account') === null){
      navigate('/app')
    }
    const intervalId = setInterval(refetchData, 5 * 60 * 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <h1 className={Heading1Style}>
        Projects
      </h1>
      <div className="my-5 flex justify-between gap-1">
        <div className="relative text-gray-200 focus-within:text-gray-400">
          <span className="absolute text-customGray-light inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                   viewBox="0 0 24 24" className="w-6 h-6"><path
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </button>
          </span>
          <input type="search" name="q"
                 className="border border-gray-300 dark:border-customGray-dark py-2 text-sm text-customGray-dark dark:text-customGray-light bg-white dark:bg-customDarkButtonBg rounded-md pl-10 focus:outline-none focus:text-customGray-dark w-[100%]"
                 placeholder="Search..." autoComplete="off" onChange={changeSearch} />
        </div>
        <Link to="/app/projects/create">
          <button className="border border-gray-300 dark:border-customGray-dark py-2 px-4 text-sm dark:text-customGray-light bg-white dark:bg-customDarkButtonBg dark:hover:bg-customDarkBg rounded-md flex">
            <GoBrowser className="h-5 w-5 mr-3"/>
            <div className="">
              Create <span className="hidden md:inline-block">Project</span>
            </div>
          </button>
        </Link>
      </div>
      <div className="flex flex-wrap gap-4">
        {results.map(project => {
          return (
            <div key={project.id} className="dark:bg-customDarkButtonBg flex justify-between flex-col bg-white p-5 rounded-lg w-full md:basis-1/2-gap-3 lg:basis-1/4-gap-3">
              <div className="flex justify-between">
                <div>
                  <Link to={`/app/projects/${project.id}`}>
                    <h1 className="text-lg text-sm font-medium mb-3 dark:text-customGreen">
                      {project.name}
                    </h1>
                  </Link>
                  <p className="text-xs dark:text-customGray-lightest">
                    {project.project_type}
                  </p>
                  {
                    project.last_test ? <p className="text-xs dark:text-customGray-lightest">
                      {dayjs(project.last_test).format('MMM D h:mm A')}
                    </p> : null
                  }
                </div>
                {
                  project.last_screenshot ? <Link to={project.last_screenshot.url} target="_blank">
                    <img className="w-[90px] h-[45px]" src={project.last_screenshot.thumbnail_s3_url} alt={`${project.last_screenshot.url} Screenshot`} />
                  </Link> : <div>
                    {
                      project.project_type === 'Website' ? <GoBrowser className="h-7 w-7 dark:text-customGray-light"/> : <BsCodeSlash  className="h-7 w-7 dark:text-customGray-light"/>
                    }
                  </div>
                }
              </div>
              <div className="flex justify-between mt-5">
                <StatusButton status={project.project_status} />
                <div className="flex flex-col justify-center dark:text-customGray-lightest">
                  <Link to={`/app/projects/${project.id}/settings`}>
                    <GoGear/>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
        {
          (results.length === 0) ? <div className="bg-white dark:bg-customDarkButtonBg dark:text-customGray-lightest w-full text-center p-10 rounded-md">
            <div className="flex justify-center">
              <TbWorldCheck className="h-[50px] w-[50px] text-customGray-light m-5" />
            </div>
            <div>You have no projects, <Link to="/app/projects/create" className="text-customGreen">create one</Link></div>
          </div> : null
        }
      </div>
    </div>
  )
}

export default Search