export const capitalize = (str, lower = false) => {
  if(str === undefined)
    return ""
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

export const toCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount)
}

export const getAcronym = (str) => {
  var matches = str.match(/\b(\w)/g);
  if(matches === null){
    return ""
  }
  var acronym = matches.join(''); // JSON

  return acronym.slice(0, 2)
}

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}