import React, {useEffect, useState} from 'react';
import AuthLayout from "../../../layouts/Auth";
import FormContainer from "../FormContainer";
import {useNavigate, useParams} from "react-router-dom";
import {verify} from "../../../services/APIService/Auth";
import dayjs from "dayjs";

const Verify = () => {
  let {id, token} = useParams()
  const navigate = useNavigate()
  const [message, setMessage] = useState("")

  useEffect(() => {
    verify(id, token)
      .then(r => {
        let user = JSON.parse(localStorage.getItem('user'))
        user.email_verified_at = dayjs()
        localStorage.setItem('user', JSON.stringify(user))
        window.dispatchEvent(new Event("storage"))
        navigate('/app/settings/verify_email')
      })
      .catch(e => {
        setMessage(e.response.data.message)
        console.log(e)
      })
  }, [id, token, navigate]);

  return (
    <AuthLayout>
      <div>
        <FormContainer>
          <p className="text-center text-2xl font-bold mb-10">
            Verifying your email address
          </p>
          {message ? <p className="text-center text-red-500">
            {message}
          </p> : null}
        </FormContainer>
      </div>
    </AuthLayout>
  )
}

export default Verify