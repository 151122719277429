import fetch from "../../auth/FetchInterceptor";
import {getCurrentSelectedAccountId} from "../Auth";

export const getProjects = () => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects`,
    method: 'GET',
  })
}

export const createProject = (data) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects`,
    method: 'POST',
    data
  })
}

export const updateProject = (projectId, data) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects/${projectId}`,
    method: 'PUT',
    data
  })
}

export const getProject = (projectId) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects/${projectId}`,
    method: 'GET',
  })
}

export const deleteProject = (projectId) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects/${projectId}`,
    method: 'DELETE',
  })
}

export const configureScreenshot = (projectId, data) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/${projectId}/screenshots`,
    method: 'POST',
    data
  })
}

export const setHealthEndpoint = (projectId, data) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/${projectId}/apis/set`,
    method: 'POST',
    data
  })
}

export const setAsKeyScreenshot = (projectId, screenshotId) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/${projectId}/screenshots/${screenshotId}`,
    method: 'PUT'
  })
}

export const testProject = (projectId) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects/${projectId}/test`,
    method: 'POST'
  })
}

export const upgradeProject = (projectId) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects/${projectId}/paid`,
    method: 'POST'
  })
}

export const simulateError = (projectId) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects/${projectId}/simulate`,
    method: 'POST'
  })
}

export const estimateProject = () => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/projects/estimate`,
    method: 'GET'
  })
}

export const getProjectForStatusPage = (uniqueId) => {
  return fetch({
    url: `/projects/${uniqueId}`,
    method: 'GET'
  })
}

export const updateStatusPage = (projectId, data) => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(data)){
    formData.append(key, value)
  }

  return fetch({
    url: `/${getCurrentSelectedAccountId()}/${projectId}/apis/status_page`,
    method: 'POST',
    data: formData
  })
}