import {buttonDangerStyle, buttonStyle} from "../styles/forms";
import {useRouteError} from "react-router-dom";
import * as Sentry from "@sentry/react";

export default function ErrorPage() {
  const error = useRouteError()
  if(process.env.REACT_APP_DEBUG){
    Sentry.captureException(error)
  }

  const clearLocalStorageAndReload = () => {
    localStorage.clear();
    window.location.href = process.env.REACT_APP_URL
  }

  return (
    <div className="w-screen h-screen text-center flex flex-col justify-center dark:text-customGray-lightest">
      <div className="text-[32px] m-5">Oops!</div>
      <div className="flex justify-center mb-3">
        <div className="max-w-xl">
          <p>
            We apologize, but it seems that an error has occurred. Our team has been notified and is working diligently
            to
            resolve this issue and get you back on track.
          </p><br/>
          <p>
            Click on Back Home to go back to the home page. If the problem
            persist, click on Clear Cache and Reload to restart the site.
          </p>
        </div>
      </div>
      {
        error ? <div>Error: {error.message}</div> : null
      }
      <div className="flex justify-center m-5">
        <div className={buttonDangerStyle + " max-w-[300px] cursor-pointer mx-3"}
             onClick={() => clearLocalStorageAndReload()}>
          Clear Cache and Reload
        </div>
        <a href={"/"} className={buttonStyle + " max-w-[300px]"}>
          Back Home
        </a>
      </div>
    </div>
  );
}