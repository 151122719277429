import React, {useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {useForm} from "react-hook-form";
import {buttonStyle} from "../../../../styles/forms";
import {useLoaderData} from "react-router-dom";
import {updateCurrentUserSettings} from "../../../../services/APIService/Auth";
import {formatPhoneNumber} from "../../../../services/String";

const Notifications = () => {
  const {data} = useLoaderData()

  let defaultValues = {
    email: data.data.settings.settings.notifications.includes("email"),
    text: data.data.settings.settings.notifications.includes("sms"),
  }

  const {register, handleSubmit} = useForm({
    defaultValues
  });

  const [loading, setLoading] = useState(false)

  const onSubmit = data => {
    setLoading(true)
    const payload = {
      email_notifications: data.email,
      sms_notifications: data.text,
    }
    updateCurrentUserSettings(payload)
      .then(res => {
        setLoading(false)
        console.log(res.data)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  };

  return (
    <div className="w-full mx-3">
      <Card title="Notifications">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" {...register(`email`)}/>
              <div
                className="w-11 h-6 bg-gray-200 dark:bg-customGray-dark peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-customGreen"></div>
              <span className="ml-3 text-sm text-customGray-dark dark:text-customGray-lightest">Send Emails to {data.data.email}</span>
            </label>
          </div>
          {
            <div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input disabled={data.data.phone === null} type="checkbox" className="sr-only peer" {...register(`text`)}/>
                <div
                  className="w-11 h-6 bg-gray-200 dark:bg-customGray-dark peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-customGreen"></div>
                <span className="ml-3 text-sm text-customGray-dark dark:text-customGray-lightest">{
                  data.data.phone === null ? `Add a phone number to enable text notifications` : `Send Texts to ${formatPhoneNumber(data.data.phone)}`
                }</span>
              </label>
            </div>
          }
          <div className="flex justify-end">
            <div className="text-right">
              <button className={buttonStyle} type="submit" disabled={loading}>
                Update Recipients
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  )
}

export default Notifications