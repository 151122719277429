import React from 'react';
import {Line} from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from "chart.js";

const GraphBar = ({stats, label = 'ms'}) => {
  const labels = []//x lables
  const dataValues = []//y values
  for (let stat of stats){
    labels.push(stat.day)
    dataValues.push(stat.healthy ? stat.healthy : 0)
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    scales: {
      y: {
        display: false,
        title: {
          display: false,
        },
        min: 0
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  }

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: label,
        data: dataValues,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <div>
      <Line options={options} data={data} height={"50px"} />
    </div>
  )
}

export default GraphBar