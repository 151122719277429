import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {buttonStyle, formBlock, inputErrorStyle, labelStyle} from "../../../styles/forms";
import {configureScreenshot, setAsKeyScreenshot} from "../../../services/APIService/Projects";

const ConfigureKeyScreenshot = ({projectId, fetchProject, cancelFunction, showCancel}) => {
  const {register, handleSubmit, formState: {errors}} = useForm({});
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [keyScreenshot, setKeyScreenshot] = useState(false)

  const onSubmit = (data) => {
    setLoading(true)
    configureScreenshot(projectId, data)
      .then(res => {
        setKeyScreenshot(res.data.data.screenshot)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const submitKeyScreenshot = () => {
    setLoading(true)
    setAsKeyScreenshot(projectId, keyScreenshot.id)
      .then(res => {
        // navigate(`/app/projects/${projectId}`)
        fetchProject()
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <>
      {
        !keyScreenshot ? <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className={formBlock}>
            <label className={labelStyle}>Url</label>
            <div className="relative mb-4 flex flex-wrap items-stretch">
              <span
                className="flex text-gray-400 items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6]"
                id="basic-addon3">https://</span>
              <input
                type="text"
                placeholder="www.example.com"
                className="relative shadow m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:text-customGray-lightest"
                {...register("url", { required: true, onChange: () => setMessage("") })}
                aria-describedby="basic-addon3"/>
            </div>
            {errors.name && <p className={inputErrorStyle}>Url is required and must be valid</p>}
          </div>
          <div className="flex justify-end">
            <div className="flex gap-3">
              {
                showCancel ? <button className={buttonStyle} onClick={cancelFunction}>
                  Cancel
                </button> : null
              }
              <button className={buttonStyle} type="submit" disabled={loading}>
                Configure Screenshot
              </button>
            </div>
            {message && <p className={inputErrorStyle}>{message}</p>}
          </div>
        </form> : <div className="flex flex-col md:flex-row gap-5">
          <div className="md:w-[400px] border">
            <a href={keyScreenshot.s3_url} target="_blank" rel="noreferrer">
              <img src={keyScreenshot.s3_url} alt={keyScreenshot.url}/>
            </a>
          </div>
          <div>
            <p className="dark:text-customGray-lightest">
              Does this screenshot look ok?
            </p>
            <div className="flex gap-5 mt-3">
              <button className={buttonStyle} onClick={() => submitKeyScreenshot()} disabled={loading}>
                Looks Good!
              </button>
              <button className={buttonStyle + ' !bg-red-500'} onClick={() => {
                setKeyScreenshot(false)
              }}>
                No, retake
              </button>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ConfigureKeyScreenshot