import React, {useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {
  buttonStyle,
  formBlock,
  inputErrorStyle,
  inputSelectStyle,
  inputStyle,
  labelStyle
} from "../../../../styles/forms";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {inviteUser} from "../../../../services/APIService/Accounts";

const Invite = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({});
  const [message, setMessage] = useState("")
  const navigate = useNavigate()

  const onSubmit = (data) => {
    inviteUser(data)
      .then((res) => {
        navigate(`/app/settings/users`)
      })
      .catch(err => {
        setMessage(err.response.message)
      })
  }

  return (
    <div className="w-full mx-3">
      <Card title="Invite">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={formBlock}>
            <label className={labelStyle}>Email</label>
            <input className={inputStyle} type="email" {...register("email", { required: true, pattern: /^\S+@\S+$/i, onChange: () => setMessage("") })} />
            {errors.email && <p className={inputErrorStyle}>Email is required and must be valid</p>}
          </div>
          <div className={formBlock}>
            <label className={labelStyle}>Role</label>
            <select className={inputSelectStyle} {...register("role", { required: true, onChange: () => setMessage("") })}>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
            {errors.role && <p className={inputErrorStyle}>Role is required and must be valid</p>}
          </div>
          <div className="flex justify-end">
            <button className={buttonStyle} type="submit">
              Invite
            </button>
            {message && <p className={inputErrorStyle}>{message}</p>}
          </div>
        </form>
      </Card>
    </div>
  )
}

export default Invite