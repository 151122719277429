import React from 'react';
import {BsCheckCircle} from "react-icons/bs";

const StatusBar = ({stats, overall, status, darkModeEnabled = true}) => {
  return (
    <div className="mb-3">
      <div className={`flex justify-between ${darkModeEnabled ? 'dark:text-customGray-lightest' : ''}`}>
        <div className="flex gap-2">
          <div className="flex flex-col justify-center text-customGreen">
            <BsCheckCircle/>
          </div>
          <div>
            {status}
          </div>
        </div>
        <div>
          {overall * 100}% uptime
        </div>
      </div>
      <div
        className="flex [&>div]:h-[50px] [&>div]:w-[100%] gap-[1px] bg-red [&>*:first-child]:rounded-l [&>*:last-child]:rounded-r">
        {
          stats.map(stat => {
            let backgroundColor
            if (stat.healthy === 1) {
              backgroundColor = 'bg-customGreen'
            } else if (stat.healthy > .8) {
              backgroundColor = 'bg-customGreen opacity-50'
            } else if (stat.healthy === null) {
              backgroundColor = 'bg-customGreen opacity-25'
            } else {
              backgroundColor = 'bg-red-500'
            }
            return (
              <>
                <div className={`${backgroundColor} group relative flex hover:opacity-80`}>
                    <span
                      className="absolute left-[-50px] top-[52px] scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap w-fit">
                      <p>
                        {stat.day}
                      </p>
                      <p>
                        Uptime: {stat.healthy === null ? "-" : (stat.healthy * 100) + '%'}
                      </p>
                    </span>
                </div>
              </>
            )
          })
        }
      </div>
      <div className={`flex justify-between opacity-25 ${darkModeEnabled ? 'dark:text-white' : ''} text-sm`}>
        <div>
          90 days ago
        </div>
        <div>
          Today
        </div>
      </div>
    </div>
  )
}

export default StatusBar