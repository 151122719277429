import axios from "axios";
import {Log} from "../services/Log";
import toast from "react-hot-toast";
import * as Sentry from "@sentry/react";

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000
})

service.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem('token')

  if (jwtToken) {
    config.headers['authorization'] = "Bearer " + jwtToken
  }

  return config
}, error => {
  Promise.reject(error)
})

service.interceptors.response.use((response) => {
  if(response.data.message){
    toast.success(response.data.message)
  }
  return response
}, (error) => {
  if(error.response.data.message){
    toast.error(error.response.data.message)
  }
  Log(error)
  if(process.env.REACT_APP_DEBUG){
    // Sentry.captureException(error)
    Sentry.captureException(new Error('Error response from API: ' + JSON.stringify(error.response)))
  }
  // return Promise.reject(error)
  if(error.response.status >= 500){
    Promise.reject(error)
  }else{
    return Promise.resolve(error.response)
  }
})

export default service