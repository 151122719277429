import dayjs from "dayjs";

export const parseIntoDisplayStats = (project) => {
  const today = dayjs()
  const overall = project.api_stats.total_healthy_tests / project.api_stats.total_tests

  const stats = []
  const servicesStats = {}

  if(project.api_stats.services){
    for (let [key,value] of Object.entries(project.api_stats.services)){
      servicesStats[value.name] = {
        name: value.name,
        stats: [],
        type: (value.total < value.sum) ? 'number' : 'boolean',
        label: value.label,
        total: value.total,
        sum: value.sum,
        total_over_sum: value.sum / value.total,
        key: key
      }
    }
  }

  for (let i = 1; i < 91; i++) {
    let healthy = null
    let day = today.subtract(90 - i, 'days')
    let dayKey = day.format('YYYY-M-D')

    if (project.api_stats.stats.per_day[dayKey]) {
      healthy = project.api_stats.stats.per_day[dayKey].day_healthy_tests / project.api_stats.stats.per_day[dayKey].day_total_tests
    }

    stats.push({
      day: day.format("MMM D"),
      healthy: healthy,
    })

    if(project.api_stats.services){
      for (let [key, value] of Object.entries(project.api_stats.services)){//eslint-disable-line
        let serviceHealth = null
        if (project.api_stats.stats.per_day[dayKey] && project.api_stats.stats.per_day[dayKey].services && project.api_stats.stats.per_day[dayKey].services[value.name]) {
          serviceHealth = project.api_stats.stats.per_day[dayKey].services[value.name].sum / project.api_stats.stats.per_day[dayKey].services[value.name].total
        }
        servicesStats[value.name].stats.push({
          day: day.format("MMM D"),
          healthy: serviceHealth,
        })
      }
    }
  }

  let displayStats = []
  for (let [key, value] of Object.entries(servicesStats)){//eslint-disable-line
    displayStats.push(value)
  }

  return {
    displayStats,
    stats,
    overall
  }
}