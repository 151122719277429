import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {getDirtyValues} from "../../../services/Forms";
import {updateCurrentPhone} from "../../../services/APIService/Auth";
import toast from "react-hot-toast";
import {
  buttonDangerStyle,
  buttonStyle,
  formBlock,
  inputErrorStyle,
  inputStyle,
  labelStyle
} from "../../../styles/forms";
import {formatPhoneNumber} from "../../../services/String";

const UpdatePhone = ({user}) => {
  let defaultValues = {
    phone: user.phone
  }

  const {register, handleSubmit, formState: {errors}} = useForm({
    defaultValues
  });
  const [message, setMessage] = useState("")
  const [confirmationMessage, setConfirmationMessage] = useState((user.phone !== null && user.phone_verified_at === null) ? "We sent a text to " + formatPhoneNumber(user.phone) + " please reply ACCEPT to confirm your phone number" : "")

  const onSubmit = (data) => {
    const dirtyValues = getDirtyValues(data, defaultValues)
    if (Object.keys(dirtyValues).length) {
      updateCurrentPhone(dirtyValues)
        .then(res => {
          setConfirmationMessage(res.data.message)
        })
        .catch(err => {
          setMessage(err.response.data.message)
        })
    } else {
      toast.error('No changes in form')
    }
  }

  return (
    <>
      {
        confirmationMessage !== '' ? <div>
          <p className="text-sm dark:text-customGray-lightest">
            {confirmationMessage}
          </p>
          <div className="flex justify-end">
            <button className={buttonDangerStyle} onClick={() => setConfirmationMessage("")}>
              Cancel
            </button>
          </div>
        </div> : <form onSubmit={handleSubmit(onSubmit)}>
          <div className={formBlock}>
            <label className={labelStyle}>Phone</label>
            <input placeholder="(222) 333-4444" className={inputStyle} type="text" {...register("phone", {
              required: true,
              onChange: () => setMessage("")
            })} />
            {errors.phone && <p className={inputErrorStyle}>Phone is required and must be valid</p>}
          </div>
          <div className="flex justify-end">
            <button className={buttonStyle} type="submit">
              Update Phone
            </button>
            {message && <p className={inputErrorStyle}>{message}</p>}
          </div>
        </form>
      }
    </>
  )
}

export default UpdatePhone