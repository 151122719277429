import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {buttonStyle, formBlock, inputErrorStyle, labelStyle} from "../../../styles/forms";
import {setHealthEndpoint} from "../../../services/APIService/Projects";
import {CodeBlock, codepen} from "react-code-blocks";
import Card from "../../Shared/Card";
import Warning from "../../Shared/Warning";
import {useNavigate} from "react-router-dom";
import UpgradeApiWarning from "../UpgradeApiWarning";

const ConfigureHealthEndpoint = ({project, fetchProject, cancelFunction, showCancel}) => {
  const {register, handleSubmit, formState: {errors}} = useForm({});
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [endpointResponse, setEndpointResponse] = useState(project.api_stats ? project.api_stats.last_healthy_test : false)
  const navigate = useNavigate()
  const exampleGoodResponse = `{
    "status": "Healthy",
    "services": [ //Services are optional, the three possible ways to add services are outlined in the examples below
      {
        "name": "Phones",
        "value": 1 //booleans can be 1/0 or true/false
      },
      {
        "name": "Servers",
        "value": 52,
        "value_label": "ms"//Add any lable of type string
      },
      {
        "name": "Database",
        "value": true
      }
    ]
  }`

  const onSubmit = (data) => {
    setLoading(true)
    setHealthEndpoint(project.id, data)
      .then(res => {
        setEndpointResponse(res.data.response)
        setLoading(false)
        navigate(`.`, {replace: true})
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <Card title={'Health Endpoint'} rightComponent={<button className={`${buttonStyle} ${!endpointResponse ? 'hidden' : ''}`} onClick={() => setEndpointResponse(false)}>Configure Endpoint</button>}>
      {
        !endpointResponse ? <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className={formBlock}>
            <label className={labelStyle}>Health Endpoint</label>
            <div className="relative mb-4 flex flex-wrap items-stretch">
              <span
                className="flex text-gray-400 items-center whitespace-nowrap rounded-l border border-r-0 border-solid border-neutral-300 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6]"
                id="basic-addon3">https://</span>
              <input
                type="text"
                placeholder="api.example.com/health"
                className="relative shadow m-0 block w-[1px] min-w-0 flex-auto rounded-r border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:text-customGray-lightest"
                {...register("health_endpoint", {required: true, onChange: () => setMessage("")})}
                aria-describedby="basic-addon3"/>
            </div>
            {errors.name && <p className={inputErrorStyle}>Health Endpoint is required and must be valid</p>}
          </div>
          <div className="flex justify-end">
            <div className="flex gap-3">
              {
                showCancel ? <button className={buttonStyle} onClick={cancelFunction}>
                  Cancel
                </button> : null
              }
              <button className={buttonStyle} type="submit" disabled={loading}>
                Set Health Endpoint
              </button>
            </div>
            {message && <p className={inputErrorStyle}>{message}</p>}
          </div>
        </form> : <div className="dark:text-customGray-lightest">
          {
            project.free ? <UpgradeApiWarning projectId={project.id} /> : null
          }
          <div className="pb-5">
            Endpoint:
            <CodeBlock showLineNumbers={true} theme={codepen}
                       text={project.settings.settings.health_endpoint}/>
          </div>
          {
            !endpointResponse.success ? <Warning>
              There is a problem with your health endpoint. It should return a properly formatted JSON response.
            </Warning> : null
          }
          <div>
            Returned JSON Response:
            <CodeBlock language="json" theme={codepen} text={JSON.stringify(endpointResponse.response ? endpointResponse.response : null, null, 2)}
                       showLineNumbers={true}/>
          </div>
          {
            !endpointResponse.success ? <div className="mt-5">
              Example of Good JSON response:
              {/*make this collapsable*/}
              <CodeBlock language="json" theme={codepen} text={exampleGoodResponse}
                         showLineNumbers={true}/>
            </div> : null
          }
        </div>
      }
    </Card>
  )
}

export default ConfigureHealthEndpoint