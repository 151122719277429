import React, {useState} from 'react';
import {formBlock, inputErrorStyle, inputErrorStyleCenter, inputStyle, labelStyle} from "../../../styles/forms";
import FormContainer from "../FormContainer";
import AuthLayout from "../../../layouts/Auth";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import APIService from "../../../services/APIService";
import {saveLoginResponse} from "../../../services/Auth";

const Login = () => {
  const {register, handleSubmit, formState: {errors}} = useForm();
  const [message, setMessage] = useState("")
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect')

  const onSubmit = (data) => {
    APIService.login(data)
      .then(res => {
        saveLoginResponse(res.data)
        switch (redirect) {
          case 'invoices':
            navigate('/app/settings/invoices')
            break;
          default:
            navigate('/app')
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message)
        }
      })
  };

  return (
    <AuthLayout>
      <div>
        <FormContainer>
          <p className="text-center text-2xl font-bold mb-10 dark:text-customGray-lightest">
            Sign in to your account
          </p>
          <form className="w-[400px]" onSubmit={handleSubmit(onSubmit)}>
            <div className={formBlock}>
              <label className={labelStyle}>Email</label>
              <input className={inputStyle} type="email" {...register("email", {
                required: true,
                pattern: /^\S+@\S+$/i,
                onChange: () => setMessage("")
              })} />
              {errors.email && <p className={inputErrorStyle}>Email is required and must be valid</p>}
            </div>
            <div className={formBlock}>
              <label className={labelStyle + ' flex justify-between'}>
                <div>
                  Password
                </div>
                <div className="text-customGreen cursor-pointer">
                  <Link to={'/auth/email'}>
                    Forgot Password
                  </Link>
                </div>
              </label>
              <input className={inputStyle} type="password" {...register("password", {
                required: true,
                onChange: () => setMessage("")
              })} />
              {errors.password && <p className={inputErrorStyle}>Password is required</p>}
            </div>
            <div className={formBlock}>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" value=""
                       className="sr-only peer" {...register("remember_me", {required: false})} />
                <div
                  className="w-11 h-6 bg-gray-200 dark:bg-customGray-dark peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-customGreen"></div>
                <span className="ml-3 text-sm text-customGray-dark">Remember me</span>
              </label>
            </div>
            <div>
              <button className="w-full bg-customGreen p-2 rounded text-white text-sm" type="submit">
                Submit
              </button>
              {message && <p className={inputErrorStyleCenter}>{message}</p>}
            </div>
          </form>
        </FormContainer>
        <div className="m-5 text-sm">
          <p className="text-center dark:text-customGray-lightest">
            Don't have an account?
          </p>
          <Link to="/auth/signup">
            <p className="text-center text-customGreen cursor-pointer">
              Sign Up
            </p>
          </Link>
        </div>
      </div>
    </AuthLayout>
  )
}

export default Login