import React from 'react';
import {BsExclamationCircle} from "react-icons/bs";

const Warning = ({children}) => {
  return (
    <div className="bg-red-100 dark:bg-customDarkRedBg dark:text-customGray-light py-3 px-5 text-sm flex gap-3 rounded mb-3">
      <div className="flex flex-col justify-center text-red-500">
        <BsExclamationCircle />
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

export default Warning