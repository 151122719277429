import React, {useEffect, useState} from 'react';
import {Link, useLoaderData, useNavigate, useOutletContext} from "react-router-dom";
import Card from "../../../../components/Shared/Card";
import {buttonStyle} from "../../../../styles/forms";
import {upgradeProject} from "../../../../services/APIService/Projects";
import {getPaymentMethodsBillingSession} from "../../../../services/APIService/Billing";

const UpgradeProject = () => {
  const [project] = useOutletContext()
  const {estimate} = useLoaderData()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [billingSessionUrl, setBillingSessionUrl] = useState("")

  const onUpgradeProject = () => {
    setLoading(true)
    upgradeProject(project.id)
      .then(res => {
        navigate(`.`, {replace: true})
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    if(project.free){
      getPaymentMethodsBillingSession({return_url: `/app/projects/${project.id}/upgrade`})
        .then(res => {
          setBillingSessionUrl(res.data.url)
        })
    }else{
      navigate(`/app/projects/${project.id}`)
    }
  }, [project]);//eslint-disable-line

  return (
    <Card title={`Upgrade Project`}>
      {estimate.needs_payment_method ? <>
        <p className="dark:text-customGray-lightest">
          In order to upgrade this project, you needs to set up a payment method. <Link to={billingSessionUrl} className="text-customGreen underline">Click Here</Link> to set one up.
        </p>
      </> : <>
        <p className="dark:text-customGray-lightest">
          Are you sure you want to upgrade the project {project.name} to a paid Project? This will cost
          ${estimate.cost_of_another_prorated} today, and add ${estimate.cost_of_another} to your bill on the 1st of every
          month.
        </p>
        <div className="flex justify-end mt-3 gap-3">
          <Link to={`/app/projects/${project.id}`} className={buttonStyle}>
            Cancel
          </Link>
          <button className={buttonStyle} type="submit" disabled={loading} onClick={() => onUpgradeProject()}>
            Upgrade
          </button>
        </div>
      </>}
    </Card>
  )
}

export default UpgradeProject