import React, {useState} from 'react';
import {formBlock, inputErrorStyle, inputErrorStyleCenter, inputStyle, labelStyle} from "../../../styles/forms";
import FormContainer from "../FormContainer";
import AuthLayout from "../../../layouts/Auth";
import {useForm} from "react-hook-form";
import {updatePasswordWithToken} from "../../../services/APIService/Auth";
import {useNavigate, useParams} from "react-router-dom";

const Reset = () => {
  const { register, watch, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState("")
  const {user_id, token} = useParams()
  const navigate = useNavigate()

  const onSubmit = (data) => {
    updatePasswordWithToken(user_id, token, data)
      .then(res => {
        navigate('/auth/login')
      })
      .catch(err => {
        setMessage(err.response.data.message)
      })
  };

  return (
    <AuthLayout>
      <div>
        <FormContainer>
          <p className="text-center text-2xl font-bold mb-10">
            Reset Password
          </p>
          <form className="w-[400px]" onSubmit={handleSubmit(onSubmit)}>
            <div className={formBlock}>
              <label className={labelStyle + ' flex justify-between'}>
                <div>
                  Password
                </div>
              </label>
              <input className={inputStyle} type="password" {...register("password", { required: true })}/>
              {errors.password && <p className={inputErrorStyle}>Password is required</p>}
            </div>
            <div className={formBlock}>
              <label className={labelStyle + ' flex justify-between'}>
                <div>
                  Confirm Password
                </div>
              </label>
              <input className={inputStyle} type="password" {...register("c_password", { required: true, validate: (val) => {
                  if (watch('password') !== val) {
                    return "Your passwords do not match";
                  }
                  if(val === ""){
                    return "Password confirmation required";
                  }
                } })}/>
              {errors.c_password && <p className={inputErrorStyle}>{errors.c_password.message === '' ? 'Password confirmation is required' : errors.c_password.message}</p>}
            </div>
            <div>
              <button className="w-full bg-customGreen p-2 rounded text-white text-sm disabled:bg-gray-500 enabled:cursor-pointer" type="submit">
                Submit
              </button>
              {message && <p className={inputErrorStyleCenter}>{message}</p>}
            </div>
          </form>
        </FormContainer>
      </div>
    </AuthLayout>
  )
}

export default Reset