export const saveLoginResponse = (LoginResponse) => {
  localStorage.setItem('token', LoginResponse.token)
  localStorage.setItem('expires_at', LoginResponse.expires_at)
  localStorage.setItem('user', JSON.stringify(LoginResponse.user))
  localStorage.setItem('mode', (LoginResponse.user.dark_mode) ? 'dark' : 'light')
  selectAccount(JSON.stringify(LoginResponse.user.accounts[0]))
  window.dispatchEvent(new Event("storage"))
}

export const selectAccount = (Account) => {
  localStorage.setItem('selected_account', Account)
  window.dispatchEvent(new Event("storage"))
}

export const updateUser = (user) => {
  if(localStorage.getItem('selected_account')){
    const currentAccount = JSON.parse(localStorage.getItem('selected_account'))
    user.accounts.forEach(account => {
      if(account.id === currentAccount.id){
        selectAccount(JSON.stringify(account))
      }
    })
  }
  localStorage.setItem('user', JSON.stringify(user))
  window.dispatchEvent(new Event("storage"))
}

export const getCurrentSelectedAccountId = () => {
  const selectedAccountString = localStorage.getItem('selected_account')
  if(selectedAccountString === null){
    return false
  }

  const selected_account = JSON.parse(selectedAccountString)
  return selected_account.id
}

export const getCurrentAccountRole = () => {
  const selectedAccountString = localStorage.getItem('selected_account')
  if(selectedAccountString === null){
    return false
  }

  const selectedAccount = JSON.parse(selectedAccountString)

  const currentRole = selectedAccount.pivot.role

  return currentRole
}

export const currentRoleIsAdmin = () => {
  const currentRole = getCurrentAccountRole()
  if(!currentRole){
    return false
  }

  if(currentRole !== 'admin'){
    return false
  }

  return true
}

export const getIfEmailIsVerified = () => {
  const userString = localStorage.getItem('user')
  if(userString === null){
    return false;
  }
  const user = JSON.parse(userString)
  return (user.email_verified_at !== null)
}