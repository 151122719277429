import fetch from "../../auth/FetchInterceptor";
import {getCurrentSelectedAccountId} from "../Auth";

export const getIssues = (accountId, data) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/issues`,
    method: 'GET',
    data
  })
}

export const getIssue = (issueId) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/issues/${issueId}`,
    method: 'GET'
  })
}

export const createIssue = (data) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/issues`,
    method: 'POST',
    data
  })
}

export const commentIssue = (issueId, data) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/issues/${issueId}/comment`,
    method: 'POST',
    data
  })
}