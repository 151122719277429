import React from 'react';

const FormContainer = ({children}) => {
  return (
    <div className="bg-white dark:bg-customDarkButtonBg shadow-lg rounded-md p-12 max-w-[500px]">
      {children}
    </div>
  )
}

export default FormContainer