import React, {useState} from 'react';
import {formBlock, inputErrorStyle, inputErrorStyleCenter, inputStyle, labelStyle} from "../../../styles/forms";
import FormContainer from "../FormContainer";
import AuthLayout from "../../../layouts/Auth";
import {useForm} from "react-hook-form";
import {sendResetPasswordEmail} from "../../../services/APIService/Auth";

const Email = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false)

  const onSubmit = (data) => {
    sendResetPasswordEmail(data)
      .then(res => {
        setFormSubmitted(true)
      })
      .catch(err => {
        setMessage(err.response.data.message)
      })
  };

  return (
    <AuthLayout>
      <div>
        <FormContainer>
          <p className="text-center text-2xl font-bold mb-10 dark:text-customGray-lightest">
            Reset Password
          </p>
          <form className="w-[400px]" onSubmit={handleSubmit(onSubmit)}>
            <div className={formBlock}>
              <label className={labelStyle}>Email</label>
              <input disabled={formSubmitted} className={inputStyle} type="email" {...register("email", { required: true, pattern: /^\S+@\S+$/i, onChange: () => setMessage("") })} />
              {errors.email && <p className={inputErrorStyle}>Email is required and must be valid</p>}
            </div>
            <div>
              <button className="w-full bg-customGreen p-2 rounded text-white text-sm disabled:bg-gray-500 enabled:cursor-pointer" type="submit" disabled={formSubmitted}>
                {
                  !formSubmitted ? "Submit" : "Email Sent"
                }
              </button>
              {message && <p className={inputErrorStyleCenter}>{message}</p>}
            </div>
          </form>
        </FormContainer>
      </div>
    </AuthLayout>
  )
}

export default Email