import React, {useEffect, useState} from 'react';
import {Heading1Style} from "../../../styles/headers";
import {capitalize} from "../../../services/String";
import {buttonStyle, disabledButtonStyle} from "../../../styles/forms";
import {selectAccount} from "../../../services/Auth";
import CreateAccount from "../Settings/AccountsSettings/CreateAccount";
import Card from "../../../components/Shared/Card";
import {useLoaderData, useNavigate} from "react-router-dom";

const Accounts = () => {
  const [selectedAccount, setSelectedAccount] = useState({id: 0})
  const [showCreateAccount, setShowCreateAccount] = useState(false)
  const {data} = useLoaderData()
  const navigate = useNavigate()

  const fetchUserAndAccountData = () => {
    const selectedAccountStorage = localStorage.getItem('selected_account')
    if (selectedAccountStorage) {
      setSelectedAccount(JSON.parse(selectedAccountStorage))
    }
  }

  const submitSelectedAccount = (account) => {
    selectAccount(JSON.stringify(account))
    setSelectedAccount(account)
    window.dispatchEvent(new Event("storage"));
  }

  useEffect(() => {
    if(data.data.length === 1){
      submitSelectedAccount(data.data[0])
      navigate('/app/projects/search')
    }

    fetchUserAndAccountData()
    window.addEventListener("storage", () => fetchUserAndAccountData())

    return () => {
      window.removeEventListener("storage", () => fetchUserAndAccountData())
    }
  }, []);// eslint-disable-line

  return (
    <div>
      <h1 className={Heading1Style}>
        Accounts
      </h1>
      {showCreateAccount ? <Card title="Create Account">
        <CreateAccount />
      </Card> : null}
      <div className="flex flex-wrap gap-4">
        {
          data.data.length > 0 ? data.data.map(account => <div key={account.id} className="flex flex-col justify-center bg-white dark:bg-customDarkButtonBg p-8 rounded-lg w-full md:w-1/2 md:basis-1/2-gap-3 lg:w-1/4 lg:basis-1/4-gap-3 min-h-[180px]">
            <p className="text-center dark:text-customGreen">
              {account.name}
            </p>
            <p className="text-center text-sm dark:text-customGray-lightest">
              {capitalize(account.pivot.role)}
            </p>
            <p className="text-center mt-3">
              {
                selectedAccount.id === account.id ? <button className={disabledButtonStyle}>
                  Selected
                </button> : <button className={buttonStyle} onClick={() => submitSelectedAccount(account)}>
                  Switch
                </button>
              }
            </p>
          </div>) : null
        }
        <div className="bg-white dark:bg-customDarkButtonBg p-8 rounded-lg w-full md:w-1/2 md:basis-1/2-gap-3 lg:w-1/4 lg:basis-1/4-gap-3 flex flex-col justify-center min-h-[180px]">
          <div className="flex justify-center">
            <button className={buttonStyle} onClick={() => setShowCreateAccount(!showCreateAccount)}>
              Create New
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accounts