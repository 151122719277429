import React, {useEffect, useState} from 'react';
import {buttonStyle, formBlock, inputErrorStyle, inputStyle, labelStyle} from "../../../../../styles/forms";
import {useForm} from "react-hook-form";
import {getSpecificAccount, updateAccount} from "../../../../../services/APIService/Accounts";
import {updateUser} from "../../../../../services/Auth";
import {useParams} from "react-router-dom";
import {getCurrentUser} from "../../../../../services/APIService/Auth";
import toast from "react-hot-toast";

const EditAccount = () => {
  const {register, setValue, handleSubmit, formState: {errors}} = useForm({});
  const [message, setMessage] = useState("")
  const {account_id} = useParams()
  const [loading, setLoading] = useState(false)

  const fetchAccount = () => {
    getSpecificAccount(account_id)
      .then(res => {
        setValue("name", res.data.data.name)
        setValue("billing_email", res.data.data.billing_email)
      })
      .catch(err => {
        console.log(err)
      })
  }
  const onUpdateAccount = (data) => {
    setLoading(true)
    updateAccount(account_id, data)
      .then((res) => {
        //update localStorage
        getCurrentUser()
          .then(res => {
            updateUser(res.data.data)
            toast.success('Account Updated')
          })
          .catch(err => {
            console.log(err)
          })
        setLoading(false)
      })
      .catch(err => {
        setMessage(err.response.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchAccount()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <form onSubmit={handleSubmit(onUpdateAccount)}>
        <div className={formBlock}>
          <label className={labelStyle}>Account Name</label>
          <input className={inputStyle} type="text" {...register("name", {
            required: true,
            onChange: () => setMessage("")
          })} />
          {errors.name && <p className={inputErrorStyle}>Name is required and must be valid</p>}
        </div>
        <div className={formBlock}>
          <label className={labelStyle}>Billing Email</label>
          <input className={inputStyle} type="text" {...register("billing_email", {
            required: true,
            pattern: /^\S+@\S+$/i,
            onChange: () => setMessage("")
          })} />
          {errors.billing_email && <p className={inputErrorStyle}>Billing email is required and must be valid</p>}
        </div>
        <div className="flex justify-end">
          <button disabled={loading} className={buttonStyle} type="submit">
            Update Account
          </button>
          {message && <p className={inputErrorStyle}>{message}</p>}
        </div>
      </form>
    </div>
  )
}

export default EditAccount