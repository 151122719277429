import React, {useState} from 'react';
import Card from "../../../../components/Shared/Card";
import {buttonStyleRight} from "../../../../styles/forms";
import {resendVerificationEmail} from "../../../../services/APIService/Auth";
import {Link} from "react-router-dom";

const VerifyEmail = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [message, setMessage] = useState("")

  const onSubmit = () => {
    setFormSubmitted(true)
    resendVerificationEmail()
      .then(r => {
        setMessage('Email Sent!')
      })
      .catch(e => {
        setMessage(e.response.message)
      })
  }

  return (
    <div className="w-full mx-3 dark:text-customGray-lightest">
      <Card title="Email Verification">
        <div>
          {user.email_verified_at !== null ? <p className="text-sm">
            Your email {user.email} is verified. Go to <Link className="text-customGreen underline" to={`/app/projects`}>Projects</Link> to get started.
          </p> : <div className="text-sm">
            <p className="text-sm">
              Please check your email {user.email} for a verification email to complete your Account set up.
            </p>
            {
              !formSubmitted ? <div className="flex justify-end mt-3">
                <button onClick={() => onSubmit()} className={buttonStyleRight}>
                  Resend Verification Email
                </button>
              </div> : null
            }
          </div>}
          {message ? <div className="flex justify-end mt-3 text-customGreen">
            {message}
          </div> : null}
        </div>
      </Card>
    </div>
  )
}

export default VerifyEmail