import fetch from "../../auth/FetchInterceptor";
import {getCurrentSelectedAccountId} from "../Auth";

export const createAccount = (data) => {
  return fetch({
    url: `/accounts`,
    method: 'POST',
    data
  })
}

export const updateAccount = (accountId, data) => {
  return fetch({
    url: `/accounts/${accountId}`,
    method: 'PUT',
    data
  })
}

export const getAccount = () => {
  return fetch({
    url: `/accounts/${getCurrentSelectedAccountId()}`,
    method: 'GET'
  })
}

export const getAccounts = () => {
  return fetch({
    url: `/accounts`,
    method: 'GET'
  })
}

export const getSpecificAccount = (accountId) => {
  return fetch({
    url: `/accounts/${accountId}`,
    method: 'GET'
  })
}

export const inviteUser = (data) => {
  return fetch({
    url: `/accounts/${getCurrentSelectedAccountId()}/invite`,
    method: 'POST',
    data
  })
}

export const cancelInvitation = (invitationId) => {
  return fetch({
    url: `/accounts/${getCurrentSelectedAccountId()}/invite/${invitationId}`,
    method: 'DELETE',
  })
}

export const removeUser = (userId) => {
  return fetch({
    url: `/accounts/${getCurrentSelectedAccountId()}/user/${userId}`,
    method: 'DELETE',
  })
}

export const deleteAccount = (accountId) => {
  return fetch({
    url: `/accounts/${accountId}`,
    method: 'DELETE',
  })
}