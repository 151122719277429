import React from 'react';
import Card from "../../../../components/Shared/Card";
import NotificationBar from "../../../../components/Shared/Notification";
import StatusBar from "../../../../components/Projects/StatusBar";
import GraphBar from "../../../../components/Projects/GraphBar";
import UpgradeApiWarning from "../../../../components/Projects/UpgradeApiWarning";
import {capitalize} from "../../../../services/String";
import {NavLink} from "react-router-dom";
import {BsGear} from "react-icons/bs";
import {parseIntoDisplayStats} from "../../../../services/StatusPage";

const StatusPage = ({project}) => {
  const {displayStats, stats, overall} = parseIntoDisplayStats(project)

  return (
    <Card title="API Status" rightComponent={project.free ? null :
      <NavLink
        to={`/app/projects/${project.id}/settings`}
        className={`flex bg-customDarkGreenButtonBg px-3 rounded-md max-h-[28px] w-fit text-sm text-white gap-1`}>
        {project.status_page?.page_status ? capitalize(project.status_page?.page_status) : 'Draft'}
        <div className="flex flex-col justify-center">
          <BsGear />
        </div>
      </NavLink>}>
      {
        project.free ? <UpgradeApiWarning projectId={project.id}/> : <NotificationBar>
          All systems operational!
        </NotificationBar>
      }
      <StatusBar stats={stats} overall={overall} status={'Active'} />
      {
        displayStats.map(serviceStat => {
          if(serviceStat.type === 'boolean'){
            return <>
              <h1 className="font-bold mt-5 mb-1 align-middle dark:text-customGray-lightest">
                {serviceStat.name}
              </h1>
              <StatusBar stats={serviceStat.stats} overall={serviceStat.total_over_sum} status={'Active'}/>
            </>
          } else {
            return <>
              <h1 className="font-bold my-5 align-middle dark:text-customGray-lightest">
                {serviceStat.name}
              </h1>
              <GraphBar
                stats={serviceStat.stats}
                label={serviceStat.label}
              />
            </>
          }
        })
      }
    </Card>
  )
}



export default StatusPage