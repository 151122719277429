import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {buttonStyle, formBlock, inputErrorStyle, inputStyle, labelStyle} from "../../../styles/forms";
import {updatePassword} from "../../../services/APIService/Auth";

const UpdatePassword = () => {
  const {register, watch, handleSubmit, formState: {errors}} = useForm()

  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    setLoading(true)
    updatePassword(data)
      .then(res => {
        setLoading(false)
      })
      .catch(err => {
        setMessage(err.response.message)
        setLoading(false)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={formBlock}>
        <label className={labelStyle + ' flex justify-between'}>
          <div>
            Current Password
          </div>
        </label>
        <input className={inputStyle} type="password" {...register("current_password", {required: true})}/>
        {errors.current_password && <p className={inputErrorStyle}>Current password is required</p>}
      </div>
      <div className={formBlock}>
        <label className={labelStyle + ' flex justify-between'}>
          <div>
            Password
          </div>
        </label>
        <input className={inputStyle} type="password" {...register("password", {required: true})}/>
        {errors.password && <p className={inputErrorStyle}>Password is required</p>}
      </div>
      <div className={formBlock}>
        <label className={labelStyle + ' flex justify-between'}>
          <div>
            Confirm Password
          </div>
        </label>
        <input className={inputStyle} type="password" {...register("c_password", {
          required: true, validate: (val) => {
            if (watch('password') !== val) {
              return "Your passwords do not match";
            }
            if (val === "") {
              return "Password confirmation required";
            }
          }
        })}/>
        {errors.c_password && <p
          className={inputErrorStyle}>{errors.c_password.message === '' ? 'Password confirmation is required' : errors.c_password.message}</p>}
      </div>
      <div className="flex justify-end">
        <button disabled={loading} className={buttonStyle} type="submit">
          Update Password
        </button>
        {message && <p className={inputErrorStyle}>{message}</p>}
      </div>
    </form>
  )
}

export default UpdatePassword