import fetch from "../../auth/FetchInterceptor";
import {getCurrentSelectedAccountId} from "../Auth";

export const getPaymentMethodsBillingSession = (data = {}) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/billing/update`,
    method: 'POST',
    data
  })
}

export const getPaymentMethodsBillingSessionManage = () => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/billing/manage`,
    method: 'POST'
  })
}

export const getInvoices = (page = 1) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/billing/invoices?page=${page}`,
    method: 'GET'
  })
}

export const estimateNextInvoice = () => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/billing/next`,
    method: 'GET'
  })
}

export const retryDueInvoices = () => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/billing/invoices/retry`,
    method: 'POST'
  })
}

export const streamInvoice = (invoiceId) => {
  return fetch({
    url: `/${getCurrentSelectedAccountId()}/billing/invoices/${invoiceId}/stream`,
    method: 'GET',
    responseType: 'blob'
  })
}