import React from 'react';
import {BsCheckCircle} from "react-icons/bs";

const NotificationBar = ({children, darkModeEnabled = true}) => {
  return (
    <div className={`bg-customGreen text-white ${darkModeEnabled ? 'dark:bg-customDarkGreenButtonBg dark:text-customGray-light' : ''} py-3 px-5 text-sm flex gap-3 rounded mb-3`}>
      <div className="flex flex-col justify-center text-white">
        <BsCheckCircle />
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

export default NotificationBar