import React from 'react';

const ConfigButton = ({children, icon, bgColorClass, iconColorClass, textColorClass, onClickFunction, disabled}) => {
  return (
    <button disabled={disabled} onClick={onClickFunction}
            className={`flex ${bgColorClass} border border-gray-500 px-5 py-1 rounded-full gap-3 max-h-[35px] disabled:bg-[#d5dff0] dark:${disabled ? "bg-customGray-darkest" : "bg-customDarkButtonBg"}`}>
      <div className={`flex flex-col justify-center ${iconColorClass} h-full dark:${disabled ? "text-customGray-light" : "text-customGray-lightest"}`}>
        {React.cloneElement(icon)}
      </div>
      <div className={`hidden md:inline-block text-customGray-dark dark:${disabled ? "text-customGray-light" : "text-customGray-lightest"} leading-6 ${textColorClass}`}>
        {children}
      </div>
    </button>
  )
}

export default ConfigButton