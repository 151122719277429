import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {buttonStyle, formBlock, inputErrorStyle, inputSelectStyle, inputStyle, labelStyle} from "../../../styles/forms";
import {createProject} from "../../../services/APIService/Projects";
import {useLoaderData, useNavigate} from "react-router-dom";
import Warning from "../../Shared/Warning";
import Card from "../../Shared/Card";
import {Heading1Style} from "../../../styles/headers";

const CreateProject = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({});
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [costWarning, setCostWarning] = useState("")
  const {data} = useLoaderData()

  const getCostOfAnotherProject = () => {
    if(data.needs_payment_method){
      navigate('/app/settings/payment_methods?error=CARD_ON_FILE_REQUIRED')
    }else{
      if(data.cost_of_another_prorated > 0){
        setCostWarning(`Creating a project will bill your default payment method $${data.cost_of_another_prorated} today and add $${data.cost_of_another} to your bill the 1st of every month.`)
      }else{
        setCostWarning(`Creating your first project is FREE.`)
      }
    }
  }

  const onSubmit = (fromData) => {
    setLoading(true)
    createProject(fromData)
      .then(res => {
        let navigateTo
        switch (fromData.project_type){
          case "API":
            navigateTo = `/app/projects/${res.data.data.id}/health`
            break
          default:
            navigateTo = `/app/projects/${res.data.data.id}/screenshots`
        }
        navigate(navigateTo)
        setLoading(false)
      })
      .catch(err => {
        if(err.response.data.error_code === 'CARD_ON_FILE_REQUIRED'){
          navigate(`/app/settings/payment_methods?error=CARD_ON_FILE_REQUIRED`)
        }
        setMessage(err.response.data.message)
        setLoading(false)
      })
  }

  useEffect(() => {
    getCostOfAnotherProject()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h1 className={Heading1Style}>
        Projects
      </h1>
      <Card title="Create Project">
        {
          costWarning ? <Warning>{costWarning}</Warning> : null
        }
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={formBlock}>
            <label className={labelStyle}>Project Type</label>
            <select className={inputSelectStyle} {...register("project_type", {
              required: true,
              onChange: () => setMessage("")
            })}>
              <option value="Website">Website</option>
              <option value="API">API</option>
            </select>
            {errors.project_type && <p className={inputErrorStyle}>Project Type is required and must be valid</p>}
          </div>
          <div className={formBlock}>
            <label className={labelStyle}>Name</label>
            <input className={inputStyle} type="text" {...register("name", {
              required: true,
              onChange: () => setMessage("")
            })} />
                 {errors.name && <p className={inputErrorStyle}>Name is required and must be valid</p>}
          </div>
          <div className="flex justify-end">
            <div className="text-right">
              {message && <p className={inputErrorStyle}>{message}</p>}
              <button className={buttonStyle} type="submit" disabled={loading}>
                Create Project
              </button>
            </div>
          </div>
        </form>
      </Card>
    </>
  )
}

export default CreateProject