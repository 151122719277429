import React from 'react';
import {NavLink, Outlet} from "react-router-dom";
import {AiFillBank, AiFillLock} from "react-icons/ai";
import {
  BsFillBellFill,
  BsFillBuildingFill,
  BsFillBuildingsFill,
  BsFillCreditCardFill,
  BsQuestionDiamondFill
} from "react-icons/bs";
import {Heading1Style} from "../../../styles/headers";
import {MdAlternateEmail} from "react-icons/md";
import {HiUsers} from "react-icons/hi";
import {getCurrentAccountRole, getCurrentSelectedAccountId} from "../../../services/Auth";

const Settings = () => {
  //get current role
  const currentRole = getCurrentAccountRole()

  const links = [
    {
      title: 'Authentication',
      to: 'authentication',
      icon: <AiFillLock />,
      roles: [
        'admin',
        'user',
      ]
    },
    {
      title: 'Account',
      to: `account/${getCurrentSelectedAccountId()}`,
      icon: <BsFillBuildingFill />,
      roles: [
        'admin',
        'user',
      ]
    },
    {
      title: 'Notifications',
      to: `notifications`,
      icon: <BsFillBellFill />,
      roles: [
        'admin',
        'user',
      ]
    },
    {
      title: 'Users',
      to: 'users',
      icon: <HiUsers />,
      roles: [
        'admin',
      ]
    },
    {
      title: 'Invoices',
      to: 'invoices',
      icon: <AiFillBank />,
      roles: [
        'admin',
      ]
    },
    {
      title: 'Payment Methods',
      to: 'payment_methods',
      icon: <BsFillCreditCardFill />,
      roles: [
        'admin',
      ]
    },
    {
      title: 'Verify Email',
      to: 'verify_email',
      icon: <MdAlternateEmail />,
      roles: [
        'admin',
        'user',
      ]
    },
    {
      title: 'Support',
      to: 'support',
      icon: <BsQuestionDiamondFill />,
      roles: [
        'admin',
        'user',
      ]
    },
  ]

  const links2 = [
    {
      title: 'Accounts',
      to: 'accounts',
      icon: <BsFillBuildingsFill />
    },
  ]

  return (
    <>
      <h1 className={Heading1Style}>
        Settings
      </h1>
      <div className="flex">
        <div className="flex flex-col">
          {links.filter(link => link.roles.includes(currentRole)).map(link => <NavLink key={link.title} to={`/app/settings/${link.to}`} className={({isActive}) => `flex mb-2 px-3 py-2 rounded-lg ${isActive ? 'bg-white dark:bg-customDarkButtonBg text-customGreen font-medium' : '[&>span]:text-customGray-darkest [&>span]:dark:text-customGray-lightest [&>div]:text-customGray-light'} w-full`}>
              <div className="flex flex-col justify-center">
                {link.icon}
              </div>
              <span className="hidden md:inline-block ml-3 mr-5 text-sm whitespace-nowrap">
                {link.title}
              </span>
            </NavLink>)}
          <div className="border-t border-gray-dark mb-2"></div>
          {links2.map(link => <NavLink key={link.title} to={`/app/settings/${link.to}`} className={({isActive}) => `flex mb-2 px-3 py-2 rounded-lg ${isActive ? 'bg-white dark:bg-customDarkButtonBg text-customGreen font-medium' : '[&>span]:text-customGray-darkest [&>span]:dark:text-customGray-lightest [&>div]:text-customGray-light'} w-full`}>
            <div className="flex flex-col justify-center">
              {link.icon}
            </div>
            <span className="ml-3 text-sm hidden md:inline-block">
                {link.title}
              </span>
          </NavLink>)}
        </div>
        <Outlet />
      </div>
    </>
  )
}

export default Settings