import React, {useState} from 'react';
import {useLoaderData} from "react-router-dom";
import Card from "../../../../../components/Shared/Card";
import {toFriendlyDate} from "../../../../../services/Date";
import {buttonStyle, inputErrorStyle, inputStyle} from "../../../../../styles/forms";
import {useForm} from "react-hook-form";
import {commentIssue, getIssue} from "../../../../../services/APIService/Support";
import IssueStatusButton from "../../../../../components/Projects/IssueStatusButton";

const Issue = () => {
  const {issue} = useLoaderData()
  const [loadIssue, setLoadIssue] = useState(issue)
  const {register, setValue, handleSubmit} = useForm({});
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    setLoading(true)
    commentIssue(loadIssue.issue_id, data)
      .then((res) => {
        fetchIssue()
        setValue('comment', '')
        setLoading(false)
      })
      .catch(err => {
        setMessage(err.response.message)
        setLoading(false)
      })
  }

  const fetchIssue = () => {
    getIssue(loadIssue.issue_id)
      .then(res => {
        setLoadIssue(res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className="w-full mx-3">
      <Card title={`${loadIssue.issue_id} ${loadIssue.summary}`} rightComponent={<IssueStatusButton status={loadIssue.status} />}>
        <p className="dark:text-customGray-lightest">
          {loadIssue.description}
        </p>
      </Card>
      {
        loadIssue.comments.length ? <Card title="Comments">
          {loadIssue.comments.map(comment => <div className="dark:text-customGray-lightest mb-3">
            <p className={comment.author === 'EST Customer' ? 'text-right' : ''}>
              {comment.author === 'EST Customer' ? <span
                className="mr-2 text-xs text-customGray-darkest dark:text-customGray-light">{toFriendlyDate(comment.created_at)}</span> : null}
              <span className="font-bold">{comment.author}</span>
              {comment.author === 'EST Customer' ? null : <span
                className="ml-2 text-xs text-customGray-darkest dark:text-customGray-light">{toFriendlyDate(comment.created_at)}</span>}
            </p>
            <p className={comment.author === 'EST Customer' ? 'text-right' : ''}>
              {comment.comment}
            </p>
          </div>)}
        </Card> : null
      }
      <Card title="Add Comment">
        <form onSubmit={handleSubmit(onSubmit)}>
          <textarea rows="4" className={inputStyle} placeholder={"Add Comment..."} {...register("comment", {
            required: true,
            onChange: () => setMessage("")
          })}/>
          <div className="flex justify-end">
            <button className={buttonStyle} disabled={loading}>
              Post Comment
            </button>
          </div>
          {message && <p className={inputErrorStyle}>{message}</p>}
        </form>
      </Card>
    </div>
  )
}

export default Issue